import { MAX_USERS } from './constants';
import { ErrorJSON, ParsedQs } from './types';

export const isArray = <T>(
  input: readonly T[] | unknown
): input is readonly T[] => Array.isArray(input);

export const isNotUndefined = <T>(input: T | undefined): input is T =>
  typeof input !== 'undefined';

export const isErrorJSON = <T>(input: T | ErrorJSON): input is ErrorJSON =>
  'error' in input;

export const isNotErrorJSON = <T>(input: T | ErrorJSON): input is T =>
  !('error' in input);

const isTruthyString = (input: unknown): input is string =>
  Boolean(typeof input === 'string' && input);

export const getUsers = (users: string | string[] | ParsedQs[] | undefined) =>
  ([] as readonly (string | undefined | ParsedQs)[])
    .concat(users)
    .map(user => typeof user === 'string' && user.trim())
    .filter(isTruthyString)
    .slice(0, MAX_USERS);

export const setUsers = (users: readonly (string | undefined)[]) =>
  users.map(user => user && user.trim()).filter(isTruthyString);
