import queryString from 'query-string';
import React, { FormEvent, PureComponent } from 'react';

import { setUsers } from '../shared/utils';
import { INITIAL_USERS } from './constants';
import { createFieldChangeHandler } from './handlers';

interface Props {
  onCloseModal: () => void;
}

interface State {
  user1: string;
  user2: string;
}

const [user1 = '', user2 = ''] = INITIAL_USERS;

const focusOnMount = (element: HTMLInputElement | null) => {
  element?.focus();
};

class PlayerModal extends PureComponent<Props, State> {
  public state = {
    user1,
    user2,
  };

  private onChangeUser1 = createFieldChangeHandler(this, 'user1');
  private onChangeUser2 = createFieldChangeHandler(this, 'user2');

  public componentDidMount() {
    window.addEventListener('keydown', this.onKey);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.onKey);
  }

  public render() {
    return (
      <div className="modal">
        <div className="overlay" />
        <div className="content">
          <form onSubmit={this.onSubmit}>
            <h1>
              <img className="icon" src="/images/icon.png" />
              <span>Halo: MCC Achievements</span>
            </h1>
            <p>
              <strong>
                Halo: Master Chief Collection Steam achievement tracker with
                filters
              </strong>
            </p>
            <p>Enter your steam username(s) to track your progress</p>
            <p>This is the last part of your profile URL</p>
            <pre>
              https://steamcommunity.com/id/
              <span className="highlight">this_bit_here</span>
            </pre>
            <label>
              Player 1
              <input
                type="text"
                value={this.state.user1}
                onChange={this.onChangeUser1}
                ref={focusOnMount}
              />
            </label>
            {this.state.user1.trim() && (
              <p>
                <a
                  target="_blank"
                  href={`https://steamcommunity.com/id/${this.state.user1.trim()}`}
                >
                  View {this.state.user1.trim()}'s profile
                </a>
              </p>
            )}
            <label>
              Player 2
              <input
                type="text"
                value={this.state.user2}
                onChange={this.onChangeUser2}
              />
            </label>
            {this.state.user2.trim() && (
              <p>
                <a
                  target="_blank"
                  href={`https://steamcommunity.com/id/${this.state.user2.trim()}`}
                >
                  View {this.state.user2.trim()}'s profile
                </a>
              </p>
            )}
            <div className="button-container">
              <button type="submit" className="primary">
                Track
              </button>
              <button type="button" onClick={this.props.onCloseModal}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  private onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const users = [this.state.user1, this.state.user2];

    const query = queryString.stringify({ user: setUsers(users) });
    window.location.assign(`/${query ? '?' : ''}${query}`);
  };

  private onKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onCloseModal();
    }
  };
}

export default PlayerModal;
