import {
  Achievement,
  PlayerAchievement,
  PlayerAndAchievements,
} from '../../shared/types';
import { isNotUndefined } from '../../shared/utils';
import { MATCHES_SPACES } from '../constants';
import {
  CompletionOptionValue,
  GameOptionValue,
  PlayerOptionValue,
  State,
  TypeOptionValue,
} from '../types';
import * as FILTERS from './constants';

export const matchesSearch = (state: State, achievement: Achievement) => {
  const terms = state.search
    .trim()
    .toLowerCase()
    .split(MATCHES_SPACES);

  if (!terms.length) {
    return true;
  }

  return terms.every(term => {
    return (
      achievement.displayName.toLowerCase().includes(term) ||
      achievement.description.toLowerCase().includes(term)
    );
  });
};

const createFilter = (names: readonly string[]) => (achievement: Achievement) =>
  names.includes(achievement.name);

const GAME_FILTER_MAP: Record<
  GameOptionValue,
  (achievement: Achievement) => boolean
> = {
  '': () => true,
  none: createFilter(FILTERS.ANY_GAME),
  haloCE: createFilter(FILTERS.HALO_CE),
  halo2: createFilter(FILTERS.HALO_2),
  halo3: createFilter(FILTERS.HALO_3),
  halo4: createFilter(FILTERS.HALO_4),
  haloODST: createFilter(FILTERS.HALO_ODST),
  haloReach: createFilter(FILTERS.HALO_REACH),
};

const MULTIPLAYER = [
  ...FILTERS.COOP,
  ...FILTERS.VERSUS,
  ...FILTERS.PLAYERS_3,
  ...FILTERS.PLAYERS_4,
];

const NOT_COOP = [...FILTERS.SOLO, ...FILTERS.VERSUS];
const COOP_ONLY = FILTERS.COOP.filter(name => !NOT_COOP.includes(name));

const SOLO_ONLY = FILTERS.SOLO.filter(name => !MULTIPLAYER.includes(name));

const PLAYER_FILTER_MAP: Record<
  PlayerOptionValue,
  (achievement: Achievement) => boolean
> = {
  '': () => true,
  solo: createFilter(FILTERS.SOLO),
  soloOnly: createFilter(SOLO_ONLY),
  coop: createFilter(FILTERS.COOP),
  coopOnly: createFilter(COOP_ONLY),
  multiplayer: createFilter(MULTIPLAYER),
  versus: createFilter(FILTERS.VERSUS),
  players3: createFilter(FILTERS.PLAYERS_3),
  players4: createFilter(FILTERS.PLAYERS_4),
};

const PAR_TIME_SCORE = [...FILTERS.PAR_TIME, ...FILTERS.PAR_SCORE];

const TYPE_FILTER_MAP: Record<
  TypeOptionValue,
  (achievement: Achievement) => boolean
> = {
  '': () => true,
  story: createFilter(FILTERS.STORY),
  specificDate: createFilter(FILTERS.SPECIFIC_DATE),
  timeBased: createFilter(FILTERS.TIME_DATE),
  scoreBased: createFilter(FILTERS.SCORE),
  killCount: createFilter(FILTERS.KILL_COUNT),
  parTime: createFilter(FILTERS.PAR_TIME),
  parScore: createFilter(FILTERS.PAR_SCORE),
  parTimeScore: createFilter(PAR_TIME_SCORE),
  collectable: createFilter(FILTERS.COLLECTABLE),
  viral: createFilter(FILTERS.VIRAL),
};

const COMPLETION_FILTER_MAP: Record<
  CompletionOptionValue,
  (playerAchievements: PlayerAchievement[]) => boolean
> = {
  '': () => true,
  complete: playerAchievements => playerAchievements.some(ach => ach.achieved),
  incomplete: playerAchievements =>
    playerAchievements.some(ach => !ach.achieved),
  completeAll: playerAchievements =>
    playerAchievements.every(ach => ach.achieved),
  incompleteAll: playerAchievements =>
    playerAchievements.every(ach => !ach.achieved),
  completeSome: playerAchievements => {
    const completedCount = playerAchievements.filter(ach => ach.achieved)
      .length;

    return completedCount > 0 && completedCount !== playerAchievements.length;
  },
};

export const matchesGame = (state: State, achievement: Achievement) =>
  GAME_FILTER_MAP[state.game](achievement);

export const matchesPlayers = (state: State, achievement: Achievement) =>
  PLAYER_FILTER_MAP[state.players](achievement);

export const matchesType = (state: State, achievement: Achievement) =>
  TYPE_FILTER_MAP[state.type](achievement);

export const matchesCompletion = (
  state: State,
  achievement: Achievement,
  playersAndAchievements: PlayerAndAchievements[] | undefined
) => {
  if (!playersAndAchievements?.length) {
    return true;
  }

  const playerAchievements = playersAndAchievements
    .map(playerAndAchievement => {
      return playerAndAchievement.achievements.find(
        playerAch => playerAch.apiname === achievement.name
      );
    })
    .filter(isNotUndefined);

  return COMPLETION_FILTER_MAP[state.completion](playerAchievements);
};
