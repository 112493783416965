import React, { ChangeEvent, KeyboardEvent, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import {
  Achievement,
  ErrorJSON,
  PlayerAchievementResult,
} from '../shared/types';
import { isArray, isErrorJSON, isNotErrorJSON } from '../shared/utils';
import {
  COMPLETION_OPTIONS,
  GAME_OPTIONS,
  INITIAL_USERS,
  PLAYER_OPTIONS,
  TYPE_OPTIONS,
} from './constants';
import {
  matchesCompletion,
  matchesGame,
  matchesPlayers,
  matchesSearch,
  matchesType,
} from './filters';
import { createFieldChangeHandler } from './handlers';
import IconCross from './icons/cross';
import IconTick from './icons/tick';
import PlayerModal from './player-modal';
import { State } from './types';

let achievements: readonly Achievement[] | ErrorJSON;
let playerAchievements: PlayerAchievementResult;

try {
  achievements = JSON.parse(
    document.getElementById('achievements')?.textContent || ''
  );
} catch (e) {
  if (window.console) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }

  achievements = {
    error: e.message,
  };
}

try {
  playerAchievements = JSON.parse(
    document.getElementById('player-achievements')?.textContent || ''
  );
} catch (e) {
  if (window.console) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }

  playerAchievements = [
    {
      error: e.message,
    },
  ];
}

let initialState: Partial<State> = {};

const storage = window.localStorage.getItem('hmcca');

if (storage) {
  try {
    initialState = JSON.parse(storage || '');
  } catch (e) {
    if (window.console) {
      // tslint:disable-next-line:no-console
      console.error(e);
    }
  }
}

const errors = playerAchievements?.filter(isErrorJSON);
const playersAndAchievements = playerAchievements?.filter(isNotErrorJSON);

class App extends PureComponent<{}, State> {
  public state: State = {
    users: INITIAL_USERS,
    search: initialState.search || '',
    completion: initialState.completion || '',
    game: initialState.game || '',
    players: initialState.players || '',
    type: initialState.type || '',
    isPlayerModalOpen: false,
  };

  private onChangeCompletion = createFieldChangeHandler(this, 'completion');
  private onChangeGame = createFieldChangeHandler(this, 'game');
  private onChangePlayers = createFieldChangeHandler(this, 'players');
  private onChangeType = createFieldChangeHandler(this, 'type');

  public setState = <K extends keyof State>(newState: Pick<State, K>) => {
    window.localStorage.setItem(
      'hmcca',
      JSON.stringify({ ...this.state, ...newState })
    );

    super.setState(newState);
  };

  public render() {
    const filteredData = isArray(achievements)
      ? achievements.filter(this.filterAchievements)
      : null;

    return (
      <>
        <CSSTransition
          in={this.state.isPlayerModalOpen}
          timeout={300}
          classNames="modal-transition"
          mountOnEnter
          unmountOnExit
        >
          <PlayerModal onCloseModal={this.onCloseModal} />
        </CSSTransition>
        <div className="header">
          <h1>
            <img className="icon" src="/images/icon.png" />
            <span>Halo: MCC Achievements</span>
          </h1>
          <a
            tabIndex={0}
            onClick={this.onClickTrack}
            onKeyDown={this.onKeyDownUser}
          >
            {this.state.users.length ? 'Change player(s)' : 'Track progress'}
          </a>
        </div>
        <div className="wrapper">
          <div className="inputs">
            <input
              id="search"
              placeholder="Search"
              value={this.state.search}
              onChange={this.onChangeSearch}
            />
            <select
              id="completion"
              onChange={this.onChangeCompletion}
              value={this.state.completion}
            >
              {COMPLETION_OPTIONS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <select
              id="game"
              onChange={this.onChangeGame}
              value={this.state.game}
            >
              {GAME_OPTIONS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <select
              id="players"
              onChange={this.onChangePlayers}
              value={this.state.players}
            >
              {PLAYER_OPTIONS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <select
              id="type"
              onChange={this.onChangeType}
              value={this.state.type}
            >
              {TYPE_OPTIONS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          {'error' in achievements && (
            <p className="error">{achievements.error}</p>
          )}
          {errors?.map((error, index) => (
            <p key={index} className="error">
              {error.error}
            </p>
          ))}
          {isArray(achievements) && isArray(filteredData) && (
            <>
              <p className="completed">
                <span>
                  Showing {filteredData.length} of {achievements.length}
                </span>
                {playersAndAchievements?.map(player => {
                  if ('error' in achievements) {
                    return null;
                  }

                  const { length: totalAchieved } = player.achievements.filter(
                    achievement => achievement.achieved
                  );

                  return (
                    <span key={player.user}>
                      {player.user}:{' '}
                      <span className="highlight">{totalAchieved}</span> (
                      {((100 / achievements.length) * totalAchieved).toFixed(1)}
                      {'%'})
                    </span>
                  );
                })}
              </p>
              <ul>
                {filteredData.map(achievement => {
                  const complete = playersAndAchievements?.every(
                    player =>
                      player.achievements.find(
                        playerAchievement =>
                          playerAchievement.apiname === achievement.name
                      )?.achieved
                  );

                  return (
                    <li
                      key={achievement.name}
                      className={`achievement${complete ? ' complete' : ''}`}
                    >
                      <img
                        className="image"
                        src={complete ? achievement.icon : achievement.icongray}
                      />
                      <div className="info">
                        <h2>{achievement.displayName}</h2>
                        <p>{achievement.description}</p>
                      </div>
                      {playersAndAchievements && (
                        <div className="completion">
                          {playersAndAchievements.map(player => {
                            const matchingAchievement = player.achievements.find(
                              playerAchievement =>
                                playerAchievement.apiname === achievement.name
                            );

                            if (matchingAchievement?.achieved) {
                              return (
                                <IconTick
                                  key={player.user}
                                  className="icon-tick"
                                  title={`${player.user} has completed`}
                                />
                              );
                            }

                            return (
                              <IconCross
                                key={player.user}
                                className="icon-cross"
                                title={`${player.user} has not completed`}
                              />
                            );
                          })}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </>
    );
  }

  private onClickTrack = () => {
    this.setState({
      isPlayerModalOpen: true,
    });
  };

  private onCloseModal = () => {
    this.setState({
      isPlayerModalOpen: false,
    });
  };

  private onKeyDownUser = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      this.onClickTrack();
    }
  };

  private onChangeSearch = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      search: value,
    });
  };

  private filterAchievements = (achievement: Achievement) => {
    return (
      matchesSearch(this.state, achievement) &&
      matchesCompletion(this.state, achievement, playersAndAchievements) &&
      matchesGame(this.state, achievement) &&
      matchesPlayers(this.state, achievement) &&
      matchesType(this.state, achievement)
    );
  };
}

ReactDOM.render(<App />, document.getElementById('app'));
