import queryString from 'query-string';

import { getUsers } from '../shared/utils';
import {
  CompletionOptionValue,
  GameOptionValue,
  Option,
  PlayerOptionValue,
  TypeOptionValue,
} from './types';

export const INITIAL_USERS = getUsers(
  queryString.parse(window.location.search, { arrayFormat: 'none' }).user
);

export const MATCHES_SPACES = /\s+/gi;

export const COMPLETION_OPTIONS: readonly Option<CompletionOptionValue>[] = [
  {
    label: 'Any completion state',
    value: '',
  },
  {
    label: 'Anyone has completed',
    value: 'complete',
  },
  {
    label: 'Anyone has not completed',
    value: 'incomplete',
  },
  {
    label: 'Everyone has completed',
    value: 'completeAll',
  },
  {
    label: 'Everyone has not completed',
    value: 'incompleteAll',
  },
  {
    label: 'Not everyone has completed',
    value: 'completeSome',
  },
];

export const GAME_OPTIONS: readonly Option<GameOptionValue>[] = [
  {
    label: 'Any game',
    value: '',
  },
  {
    label: 'Not game specific',
    value: 'none',
  },
  {
    label: 'Halo CE',
    value: 'haloCE',
  },
  {
    label: 'Halo 2',
    value: 'halo2',
  },
  {
    label: 'Halo 3',
    value: 'halo3',
  },
  {
    label: 'Halo 4',
    value: 'halo4',
  },
  {
    label: 'Halo: ODST',
    value: 'haloODST',
  },
  {
    label: 'Halo: Reach',
    value: 'haloReach',
  },
];

export const PLAYER_OPTIONS: readonly Option<PlayerOptionValue>[] = [
  {
    label: 'Any players',
    value: '',
  },
  {
    label: 'Solo',
    value: 'solo',
  },
  {
    label: 'Solo only',
    value: 'soloOnly',
  },
  {
    label: 'Coop',
    value: 'coop',
  },
  {
    label: 'Coop only',
    value: 'coopOnly',
  },
  {
    label: 'Multiplayer',
    value: 'multiplayer',
  },
  {
    label: 'Versus',
    value: 'versus',
  },
  {
    label: '3 Players',
    value: 'players3',
  },
  {
    label: '4 Players',
    value: 'players4',
  },
];

export const TYPE_OPTIONS: readonly Option<TypeOptionValue>[] = [
  {
    label: 'Any achievement type',
    value: '',
  },
  {
    label: 'Story',
    value: 'story',
  },
  {
    label: 'Specific date',
    value: 'specificDate',
  },
  {
    label: 'Time based',
    value: 'timeBased',
  },
  {
    label: 'Score based',
    value: 'scoreBased',
  },
  {
    label: 'Kill count',
    value: 'killCount',
  },
  {
    label: 'Par time',
    value: 'parTime',
  },
  {
    label: 'Par score',
    value: 'parScore',
  },
  {
    label: 'Par time or score',
    value: 'parTimeScore',
  },
  {
    label: 'Collectable',
    value: 'collectable',
  },
  {
    label: 'Viral',
    value: 'viral',
  },
];
