export const SOLO = [
  '1_0_LIFE_STORY',
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_12_THE_GUARDIANS_ARE_COMING',
  '1_14_MYSTERY_ACHIEVEMENT',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_17_TEMPERED_BLADE',
  '1_18_FORGED_IN_FIRE',
  '1_19_SAMPLE_PLATE',
  '1_20_I_DABBLE_IN_SLAYING',
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_25_LORE_MASTER',
  '1_26_YOUR_JOURNEY_BEGINS',
  '1_27_LASO_MASTER',
  '1_31_THE_SILENT_CARTOGRAPHER',
  '2_0_ASSAULT_ON_THE_CONTROL_ROOM',
  '1_28_PILLAR_OF_AUTUMN',
  '1_29_HALO',
  '1_30_TRUTH_AND_RECONCILIATION',
  '2_1_343_GUILTY_SPARK',
  '2_2_THE_LIBRARY',
  '2_3_TWO_BETRAYALS',
  '2_4_KEYES',
  '2_5_THE_MAW',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '2_28_ARRIVAL',
  '2_29_SACRIFICE',
  '2_30_SPLINTERS',
  '2_31_IDLE_HANDS',
  '3_0_HITCHHIKERS_MAY_BE_ESCAPING_CONVICTS',
  '3_1_BEARLY_CONTAINED',
  '3_2_QUIS_CUSTODIET_IPSOS_CUSTODES',
  '3_3_MEMORIES',
  '3_4_MIND_GAMES',
  '3_5_KILOTONS_OF_FUN',
  '3_6_DEAR_DIARY',
  '3_7_SKULLTAKER_HALO_CE_IRON',
  '3_8_SKULLTAKER_HALO_CE_MYTHIC',
  '3_9_SKULLTAKER_HALO_CE_BOOM',
  '3_10_SKULLTAKER_HALO_CE_FOREIGN',
  '3_11_SKULLTAKER_HALO_CE_FAMINE',
  '3_12_SKULLTAKER_HALO_CE_BANDANA',
  '3_13_SKULLTAKER_HALO_CE_FOG',
  '3_14_SKULLTAKER_HALO_CE_MALFUNCTION',
  '3_15_SKULLTAKER_HALO_CE_RECESSION',
  '3_16_SKULLTAKER_HALO_CE_BLACK_EYE',
  '3_17_SKULLTAKER_HALO_CE_EYE_PATCH',
  '3_18_SKULLTAKER_HALO_CE_PINATA',
  '3_19_SKULLTAKER_HALO_CE_GRUNT_BIRTHDAY_PARTY',
  '3_20_HEADHUNTER',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '3_24_OVERSHIELDS_ARE_FOR_SISSIES',
  '3_25_WALK_IT_OFF',
  '3_26_HOW_PEDESTRIAN',
  '3_27_THAT_JUST_HAPPENED',
  '3_28_LOOK_OUT_FOR_THE_LITTLE_GUYS',
  '3_29_LEAVE_IT_WHERE_IT_LAY',
  '3_30_TYING_UP_LOOSE_ENDS',
  '3_31_A_FINE_CHOICE',
  '4_0_EXCELLENT_TASTE',
  '4_1_LIKE_A_FINE_WINE',
  '4_2_YOU_ARE_THE_WEAPON',
  '4_3_MADRIGAL_DEBUT',
  '4_4_MY_BUDDY_CHIPS',
  '4_5_NO_SMOKING',
  '4_6_T-REX',
  '4_7_THIRSTY_GRUNT',
  '4_8_MEGG',
  '4_9_GET_ME_OUT_OF_HERE',
  '4_10_FEET_FIRMLY_PLANTED',
  '4_11_BEFORE_AND_AFTER',
  '4_14_HES_UNSTOPPABLE',
  '4_15_THE_GOOD_THE_BAD_AND_THE_DEAD',
  '4_16_A_DAY_TO_REMEMBER',
  '4_18_CAIRO_STATION',
  '4_19_OUTSKIRTS',
  '4_20_METROPOLIS',
  '4_21_THE_ARBITER',
  '4_22_ORACLE',
  '4_23_DELTA_HALO',
  '4_24_REGRET',
  '4_25_SACRED_ICON',
  '4_26_QUARANTINE_ZONE',
  '4_27_GRAVEMIND',
  '4_28_UPRISING',
  '4_29_HIGH_CHARITY',
  '4_30_THE_GREAT_JOURNEY',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '5_27_CURIOUS',
  '5_28_INQUISITIVE',
  '5_29_EXAMINER',
  '5_30_INSPECTOR',
  '5_31_FASCINATED',
  '6_0_ENCHANTED',
  '6_1_ENAMORED',
  '6_2_DETERMINED',
  '6_3_DELIGHTED',
  '6_4_KNOWLEDGABLE',
  '6_5_SCHOLAR',
  '6_6_REVIEWER',
  '6_7_OBSESSED',
  '6_8_WALKING_ENCYCLOPEDIA',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_11_SKULLTAKER_HALO_2_BLIND',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '6_25_AND_SO_IT_BEGINS',
  '6_27_REGRETTABLE_TURN_OF_EVENTS',
  '6_28_THE_WILL_OF_THE_PROPHETS',
  '6_29_CHIEFTAIN_OF_THE_BRUTES',
  '6_30_DONT_MAKE_A_GIRL_A_PROMISE',
  '6_31_MY_WORK_IS_DONE',
  '7_0_I_LIKE_CRAZY',
  '7_1_PYRRHIC_SOLUTION',
  '7_2_TOYBOX',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '7_6_SECONDS',
  '7_7_CONNOISSEUR',
  '7_8_SIX_PEDALS_FOUR_DIRECTIONS',
  '7_9_BETCHA_CANT_STICK_IT',
  '7_10_COWARDLY_GRUNT',
  '7_11_SIEGE_OF_IVORY_TOWER',
  '7_12_THE_CHIPS_DUBBO?',
  '7_13_REX_SWORD',
  '7_14_10_MINUTES_TOO_EARLY',
  '7_15_HI_BEN',
  '7_18_E_E_E',
  '7_21_GOOOAAAAALLLL',
  '7_22_NEEDS_MORE_WHAMMY_BAR',
  '7_24_STARVED_FOR_SHIELDS',
  '7_25_COMMUTER',
  '7_26_HE_NEVER_GETS_ME_ANYTHING',
  '7_27_SCARAB_LORD',
  '7_28_SILENT_BUT_DEADLY',
  '7_29_DEMON',
  '7_30_GO_APE_SHIV',
  '8_8_BACK_AT_IT',
  '8_9_BACK_FOR_MORE',
  '8_11_LANDFALL',
  '8_12_HOLDOUT',
  '8_13_THE_ROAD',
  '8_14_ASSAULT',
  '8_15_CLEANSING',
  '8_16_REFUGE',
  '8_17_LAST_STAND',
  '8_18_THE_KEY',
  '8_19_RETURN',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '9_7_RISING_WATERS',
  '9_8_INUNDATION',
  '9_9_BEFORE_THE_FIRE',
  '9_10_TIPPING_POINT',
  '9_11_UNTO_DUST',
  '9_12_BABBLING_DEAD',
  '9_13_SWORD_AND_SHIELD',
  '9_14_THE_COINS_FAULT',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '9_28_BEGINS_WITH_A_SINGLE_STEP',
  '9_29_WHO_NEEDS_THEM?',
  '9_30_PHANTOM_HUNTER',
  '10_0_DIRGE_OF_MADRIGAL',
  '10_1_PRIMATE',
  '10_2_HAPLORRHINI',
  '10_3_CANT_KEEP_HIM_DOWN',
  '10_4_FLIPYAP',
  '10_5_HEADING_TO_HIS_DESTINY',
  '10_6_SOUNDS_FAMILIAR',
  '10_8_MILK_CARTON',
  '10_9_COOL_STORY_BRO',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '10_14_THIRD_TIMES_A_CHARM',
  '10_16_DAWN',
  '10_17_REQUIEM',
  '10_18_FORERUNNER',
  '10_19_INFINITY',
  '10_20_RECLAIMER',
  '10_21_SHUTDOWN',
  '10_22_COMPOSER',
  '10_23_MIDNIGHT',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '11_10_WAR',
  '11_11_LORD_OF_ADMIRALS',
  '11_12_CHARUM_HAKKOR',
  '11_13_FLOOD',
  '11_14_KNIGHTS',
  '11_15_JUSTICE',
  '11_16_CRYPTUM',
  '11_17_TERMINUS',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_21_LONE_WOLF_LEGEND',
  '11_22_THIS_IS_MY_RIFLE_THIS_IS_MY_GUN',
  '11_23_BROS_TO_THE_CLOSE',
  '11_24_EXPLORE_THE_FLOOR',
  '11_25_GIVE_HIM_THE_STICK',
  '11_26_CHIEF_SMASH',
  '11_27_YOURE_NO_CHIPS',
  '11_28_A_LONG_TIME_AGO',
  '11_30_HES_RUNNING_A_MARATHON',
  '12_0_TIGHTEN_UP_THE_GRAPHICS',
  '12_1_MIND_THE_GAP',
  '12_3_PACIFIST',
  '12_4_WRAITH_HUNTER',
  '12_5_CLOSE_QUARTERS_COMBAT',
  '12_6_THIS_SIDE_UP',
  '12_7_MORTARDOM',
  '12_8_ALL_ACCORDING_TO_PLAN',
  '12_9_BEACHHEAD',
  '12_10_THE_RETURN_OF_MEGG',
  '12_11_NO-FLY_ZONE',
  '12_13_DIGGING_UP_THE_PAST',
  '12_14_MISSING_LINK',
  '13_10_DIRTY_BIRD',
  '13_11_A_PREFERENCE_FOR_PAIN',
  '13_12_AFICIANDO',
  '13_13_NAKED_TYRANT',
  '13_14_DEVOTEE',
  '13_15_THRONE_OF_BONES',
  '13_16_BOOT_CAMP_HERO',
  '13_17_A_PILE_OF_MEDALS',
  '13_18_A_CRATE_OF_MEDALS',
  '13_19_A_HEAP_OF_MEDALS',
  '13_20_A_FEW_TRINKETS',
  '13_21_CHEST_OF_GOLD',
  '13_22_OH_THESE_BAUBLES?',
  '13_23_CHESTY_PULLER_STARTER_KIT',
  '13_24_MEDAL_MASTER_CHIEF',
  '13_25_CONTENDER',
  '13_26_IN_IT_TO_WIN_IT',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_29_SHARPSHOOTER',
  '13_30_CANT_PUT_IT_DOWN',
  '13_31_BATTLE_HARDENED',
  '14_0_UP_ALL_NIGHT',
  '14_1_THERMOPYLAE',
  '14_2_CANT_GET_ENOUGH',
  '14_3_STRIKING_FEAR_IN_THEIR_HEARTS',
  '14_4_COMMITTED',
  '14_5_STEADY_AIM',
  '14_6_BIG_TIME_GAMER',
  '14_7_QUICK_TRIGGER_FINGER',
  '14_8_LONG_TIME_FAN',
  '14_9_MULTIPLAYER_CHAMPION',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '14_22_GREENHORN',
  '14_23_BIG_GREEN_STYLE',
  '14_24_TRAINING_WHEELS',
  '14_25_LIBRARIANS_GIFT',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '14_27_KING_OF_THE_CROWS',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '15_1_NO_STONE_UNTURNED',
  '4_17_GOAT_ROPED',
  '7_16_ANIMAL_HABITAT',
  '7_17_SID_GRAFFITI',
  '7_19_WHY_SO_SERIOUS',
  '7_20_WHY_AM_I_HERE',
  '8_0_TOUR_OF_DUTY',
  '8_10_MONOPOLIZED',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '10_15_DEVASTATING',
  '11_29_YOURE_JOKING',
  '11_31_DESTINATION_VACATION',
  '12_15_EMINENT_DOMAIN',
  '12_16_GAME_MASTER',
  '12_17_GATE_YOUR_THIRST',
  '12_18_HIGH_ALTITUDE_THIRST',
  '12_20_MASTER_FORGER',
  '12_21_POWER_PLAY',
  '12_23_RED_VS_BLUE',
  '12_24_RULE_YOUR_THIRST',
  '12_28_STAYIN_ALIVE',
  '12_30_THIRST_LOCKED_DOWN',
  '13_0_BLASTACULAR',
  '13_1_BLOODY_THIRSTY',
  '13_8_WORSHIP_YOUR_THIRST',
  '15_3_OPERATION_COMPLETION',
  '15_4_DEPARTURE',
  '15_5_ARTIFACT',
  '15_6_CATHERINE',
  '15_7_DIDACTS_HAND',
  '15_8_MEMENTO_MORI',
  '15_9_SCATTERED',
  '15_10_INVASION',
  '15_11_EXPENDABLE',
  '15_12_KEY',
  '15_13_EXODUS',
  '15_14_FEET_FIRST',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '15_17_ICARUS',
  '15_18_SMOOTH_OPERATOR',
  '15_19_ROSES_VS_VIOLETS',
  '15_20_NO_ONE_LEFT_BEHIND',
  '15_21_KNIGHT_IN_WHITE_ASSASSINATION',
  '15_22_WHAT_POWER_OUTAGE?',
  '15_23_NO_EASY_WAY_OUT',
  '16_4_NO_PAIN_NO_GAIN',
  '16_5_AIRBORNE',
  '16_6_ASSEMBLY_SKULL',
  '16_7_ORBITAL_SKULL',
  '16_8_SANDBOX_SKULL',
  '16_9_CITADEL_SKULL',
  '16_10_HERETIC_SKULL',
  '16_11_LONGSHORE_SKULL',
  '16_12_BRAINPAN',
  '16_13_SIGHTSEEING',
  '16_14_CHECKING_OUT_YOUR_OPTIONS',
  '16_15_TOURIST',
  '16_16_WINDOW_SHOPPING',
  '16_17_WORLD_TRAVELER',
  '16_18_JUST_BROWSING',
  '16_19_GOING_INTERNATIONAL',
  '16_20_TASTE_TEST',
  '19_24_MONUMENTAL_THIRST',
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '16_23_LISTENER',
  '16_24_AN_EAR_FULL',
  '16_25_TUNED_IN',
  '16_26_DO_TELL',
  '16_27_HEAR_ME_OUT',
  '16_28_EAVESDROPPING',
  '16_29_AUDITOR',
  '16_30_OVERHEARD',
  '16_31_WIRETAPPING',
  '17_0_MONITORED',
  '17_1_HANG_ONTO_MY_WORDS',
  '17_2_GIVE_HEED',
  '17_3_GET_A_LOAD',
  '17_4_SNOOP_TROOP',
  '17_5_ALL_EARS',
  '17_6_EAR_BENDER',
  '17_7_PLUGGED_IN',
  '17_8_ATTENTIVE',
  '17_9_WATCHFUL',
  '17_10_OBSERVANT',
  '17_11_SCOUT',
  '17_12_ADVENTURER',
  '17_13_SPOTTER',
  '17_14_CONSIDERATE',
  '17_15_ON_YOUR_TOES',
  '17_16_ARCHIVIST',
  '17_17_YOUR_ATTENTION_PLEASE',
  '17_18_CATCHING_ON',
  '17_19_WISENING_UP',
  '17_20_AUDIOPHILE',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '17_25_JUNIOR_DETECTIVE',
  '17_26_INVESTIGATOR',
  '17_27_GUMSHOE',
  '17_28_BLOODHOUND',
  '17_29_PRIVATE_EYE',
  '17_30_SECRET_AGENT',
  '17_31_RECORD_STORE_OWNER',
  '18_0_TAYARI_PLAZA',
  '18_1_UPLIFT_RESERVE',
  '18_2_KIZINGO_BOULEVARD',
  '18_3_ONI_ALPHA_SITE',
  '18_4_NMPD_HQ',
  '18_5_KIKOWANI_STATION',
  '18_6_DATA_HIVE',
  '18_7_COASTAL_HIGHWAY',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '18_28_SAFETY_NOT_GUARANTEED',
  '18_29_TRIFECTA',
  '18_30_NEXT',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_0_ONE_GOT_AWAY',
  '19_1_LIKE_A_BROKEN_RECORD',
  '19_2_VANDALIZED',
  '19_3_SATURDAY_MORNING_CARTOON',
  '19_4_CLASSIC',
  '19_5_CATCH_EM_NAPPING',
  '19_6_STRONG_SILENT_TYPE',
  '19_7_BIP_BAP_BAM',
  '19_8_PINEAPPLE_EXPRESS',
  '19_9_FOWL_HUNT',
  '19_10_DOES_WHATEVER_A_SPARTAN_CAN',
  '19_11_FIREFLY',
  '19_12_BLAM_SAID_THE_LADY',
  '19_13_ONE_WAY_RIDE',
  '19_14_JUST_SAYIN_I_AINT_DEAD',
  '19_15_DEFERENCE_OF_DARKNESS',
  '19_16_THOSE_LEFT_BEHIND',
  '19_17_SHOOT_AND_SCOOT',
  '19_18_FAST_AND_LOW',
  '19_19_SHINY_',
  '19_20_BITS_AND_PIECES',
  '19_21_WHAT_ABOUT_THOSE_TANKS?',
  '19_22_TWO_PLACES_SAME_TIME',
  '19_23_XENOPHOBIA',
  '19_25_WERE_JUST_GETTING_STARTED',
  '19_26_NO_PICNIC',
  '19_27_PROTOCOL_DICTATES_ACTION',
  '19_28_I_NEED_A_WEAPON',
  '19_29_TO_WAR',
  '19_30_YOU_FLEW_PRETTY_GOOD',
  '19_31_INTO_THE_HOWLING_DARK',
  '20_0_DUST_AND_ECHOES',
  '20_1_THIS_IS_NOT_YOUR_GRAVE',
  '20_2_SEND_ME_OUT__WITH_A_BANG',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '20_30_KEEP_IT_CLEAN',
  '20_31_THEYVE_ALWAYS_BEEN_FASTER',
  '21_0_WAKE_UP_BUTTERCUP',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_2_YOUR_HERESY_WILL_STAY_YOUR_FEET',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '21_4_TANK_BEATS_EVERYTHING',
  '21_5_SWIFT_AND_SHALLOW',
  '21_7_HBORG_SPECIAL',
  '21_8_PLAY_US_A_SAD_SONG_CLAUDE',
  '21_9_WERE_GONNA_NEED_A_BIGGER_SHIP',
  '21_10_FRONT_PAGE_NEWS',
  '21_11_THE_HUMBLE_BEGINNINGS_OF_WILJAX_BRANTLEY',
  '21_12_DID_YOU_MAJOR_IN_MARINE_BIO?',
  '21_14_THIS_IS_NOT_THE_GREATEST_LAB_IN_THE_WORLD',
  '21_15_HEY_YOU_DROPPED_THIS',
  '21_16_READ_HALSEYS_JOURNAL',
  '21_17_MEETING_OF_THE_(ARTIFICIAL)_MINDS',
  '21_18_RAMPANT_SPECULATION',
  '21_19_MINORITY_REPORT',
  '21_20_INFO_DUMP',
  '21_21_PAPER_TRAIL',
  '21_22_TERMINAL_CONDITIONS',
  '21_23_DIGITAL_DESTINIES',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_3_WHY_DO_THIS_TO_YOURSELF?',
  '22_4_REACH_HAS_BEEN_GOOD_TO_ME',
  '22_5_WHO_NEEDS_RED_FLAG?',
  '22_6_REMEMBER_REACH',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
  '22_8_ENEMIES_EVERYWHERE',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_12_LEFT_BEHIND',
  '22_13_ONE_FINAL_FIREFIGHT',
  '22_14_WHAT_ABOUT_BOB?',
  '22_15_JORGE_CANT_HAVE_ALL_THE_BIG_GUNS',
  '22_17_WORKERS_COMPENSATION',
  '22_22_BECAUSE_IT_WASNT_HARD_ENOUGH',
  '22_23_CLASSIC_COMPLETION',
  '22_25_COLLECTION_ELIGIBILITY_CONFIRMED',
  '22_26_MIND_THE_SKILL_GAP',
  '22_28_NEW_WAYS_TO_SAVE_A_BUCK',
  '22_16_IM_SORRY_DAVE',
  '22_18_CAPACIOUS_CARTOGRAPHY',
  '22_20_RUN_AND_GUNNED',
  '22_21_GAME_BREAKER',
  '22_24_CONFUSED_CALLOUTS',
  '22_27_EASY_TO_OVERLOOK',
];

export const COOP = [
  '1_0_LIFE_STORY',
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_12_THE_GUARDIANS_ARE_COMING',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_19_SAMPLE_PLATE',
  '1_20_I_DABBLE_IN_SLAYING',
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_25_LORE_MASTER',
  '1_26_YOUR_JOURNEY_BEGINS',
  '1_27_LASO_MASTER',
  '1_31_THE_SILENT_CARTOGRAPHER',
  '2_0_ASSAULT_ON_THE_CONTROL_ROOM',
  '1_28_PILLAR_OF_AUTUMN',
  '1_29_HALO',
  '1_30_TRUTH_AND_RECONCILIATION',
  '2_1_343_GUILTY_SPARK',
  '2_2_THE_LIBRARY',
  '2_3_TWO_BETRAYALS',
  '2_4_KEYES',
  '2_5_THE_MAW',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '2_28_ARRIVAL',
  '2_29_SACRIFICE',
  '2_30_SPLINTERS',
  '2_31_IDLE_HANDS',
  '3_0_HITCHHIKERS_MAY_BE_ESCAPING_CONVICTS',
  '3_1_BEARLY_CONTAINED',
  '3_2_QUIS_CUSTODIET_IPSOS_CUSTODES',
  '3_3_MEMORIES',
  '3_4_MIND_GAMES',
  '3_5_KILOTONS_OF_FUN',
  '3_6_DEAR_DIARY',
  '3_7_SKULLTAKER_HALO_CE_IRON',
  '3_8_SKULLTAKER_HALO_CE_MYTHIC',
  '3_9_SKULLTAKER_HALO_CE_BOOM',
  '3_10_SKULLTAKER_HALO_CE_FOREIGN',
  '3_11_SKULLTAKER_HALO_CE_FAMINE',
  '3_12_SKULLTAKER_HALO_CE_BANDANA',
  '3_13_SKULLTAKER_HALO_CE_FOG',
  '3_14_SKULLTAKER_HALO_CE_MALFUNCTION',
  '3_15_SKULLTAKER_HALO_CE_RECESSION',
  '3_16_SKULLTAKER_HALO_CE_BLACK_EYE',
  '3_17_SKULLTAKER_HALO_CE_EYE_PATCH',
  '3_18_SKULLTAKER_HALO_CE_PINATA',
  '3_19_SKULLTAKER_HALO_CE_GRUNT_BIRTHDAY_PARTY',
  '3_20_HEADHUNTER',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '3_24_OVERSHIELDS_ARE_FOR_SISSIES',
  '3_25_WALK_IT_OFF',
  '3_26_HOW_PEDESTRIAN',
  '3_27_THAT_JUST_HAPPENED',
  '3_28_LOOK_OUT_FOR_THE_LITTLE_GUYS',
  '3_29_LEAVE_IT_WHERE_IT_LAY',
  '3_30_TYING_UP_LOOSE_ENDS',
  '3_31_A_FINE_CHOICE',
  '4_0_EXCELLENT_TASTE',
  '4_1_LIKE_A_FINE_WINE',
  '4_2_YOU_ARE_THE_WEAPON',
  '4_3_MADRIGAL_DEBUT',
  '4_4_MY_BUDDY_CHIPS',
  '4_5_NO_SMOKING',
  '4_6_T-REX',
  '4_7_THIRSTY_GRUNT',
  '4_9_GET_ME_OUT_OF_HERE',
  '4_10_FEET_FIRMLY_PLANTED',
  '4_11_BEFORE_AND_AFTER',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '4_14_HES_UNSTOPPABLE',
  '4_15_THE_GOOD_THE_BAD_AND_THE_DEAD',
  '4_16_A_DAY_TO_REMEMBER',
  '4_18_CAIRO_STATION',
  '4_19_OUTSKIRTS',
  '4_20_METROPOLIS',
  '4_21_THE_ARBITER',
  '4_22_ORACLE',
  '4_23_DELTA_HALO',
  '4_24_REGRET',
  '4_25_SACRED_ICON',
  '4_26_QUARANTINE_ZONE',
  '4_27_GRAVEMIND',
  '4_28_UPRISING',
  '4_29_HIGH_CHARITY',
  '4_30_THE_GREAT_JOURNEY',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '5_27_CURIOUS',
  '5_28_INQUISITIVE',
  '5_29_EXAMINER',
  '5_30_INSPECTOR',
  '5_31_FASCINATED',
  '6_0_ENCHANTED',
  '6_1_ENAMORED',
  '6_2_DETERMINED',
  '6_3_DELIGHTED',
  '6_4_KNOWLEDGABLE',
  '6_5_SCHOLAR',
  '6_6_REVIEWER',
  '6_7_OBSESSED',
  '6_8_WALKING_ENCYCLOPEDIA',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_11_SKULLTAKER_HALO_2_BLIND',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '6_25_AND_SO_IT_BEGINS',
  '6_27_REGRETTABLE_TURN_OF_EVENTS',
  '6_28_THE_WILL_OF_THE_PROPHETS',
  '6_29_CHIEFTAIN_OF_THE_BRUTES',
  '6_30_DONT_MAKE_A_GIRL_A_PROMISE',
  '6_31_MY_WORK_IS_DONE',
  '7_0_I_LIKE_CRAZY',
  '7_1_PYRRHIC_SOLUTION',
  '7_2_TOYBOX',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '7_6_SECONDS',
  '7_7_CONNOISSEUR',
  '7_8_SIX_PEDALS_FOUR_DIRECTIONS',
  '7_9_BETCHA_CANT_STICK_IT',
  '7_10_COWARDLY_GRUNT',
  '7_11_SIEGE_OF_IVORY_TOWER',
  '7_12_THE_CHIPS_DUBBO?',
  '7_13_REX_SWORD',
  '7_14_10_MINUTES_TOO_EARLY',
  '7_15_HI_BEN',
  '7_18_E_E_E',
  '7_21_GOOOAAAAALLLL',
  '7_22_NEEDS_MORE_WHAMMY_BAR',
  '7_23_LIKE_PEANUT_BUTTER_AND_CHOCOLATE',
  '7_24_STARVED_FOR_SHIELDS',
  '7_25_COMMUTER',
  '7_26_HE_NEVER_GETS_ME_ANYTHING',
  '7_27_SCARAB_LORD',
  '7_28_SILENT_BUT_DEADLY',
  '7_29_DEMON',
  '7_30_GO_APE_SHIV',
  '8_8_BACK_AT_IT',
  '8_9_BACK_FOR_MORE',
  '8_11_LANDFALL',
  '8_12_HOLDOUT',
  '8_13_THE_ROAD',
  '8_14_ASSAULT',
  '8_15_CLEANSING',
  '8_16_REFUGE',
  '8_17_LAST_STAND',
  '8_18_THE_KEY',
  '8_19_RETURN',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '9_7_RISING_WATERS',
  '9_8_INUNDATION',
  '9_9_BEFORE_THE_FIRE',
  '9_10_TIPPING_POINT',
  '9_11_UNTO_DUST',
  '9_12_BABBLING_DEAD',
  '9_13_SWORD_AND_SHIELD',
  '9_14_THE_COINS_FAULT',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '9_28_BEGINS_WITH_A_SINGLE_STEP',
  '9_29_WHO_NEEDS_THEM?',
  '9_30_PHANTOM_HUNTER',
  '9_31_ANNUAL',
  '10_0_DIRGE_OF_MADRIGAL',
  '10_1_PRIMATE',
  '10_2_HAPLORRHINI',
  '10_3_CANT_KEEP_HIM_DOWN',
  '10_4_FLIPYAP',
  '10_5_HEADING_TO_HIS_DESTINY',
  '10_6_SOUNDS_FAMILIAR',
  '10_8_MILK_CARTON',
  '10_9_COOL_STORY_BRO',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '10_14_THIRD_TIMES_A_CHARM',
  '10_16_DAWN',
  '10_17_REQUIEM',
  '10_18_FORERUNNER',
  '10_19_INFINITY',
  '10_20_RECLAIMER',
  '10_21_SHUTDOWN',
  '10_22_COMPOSER',
  '10_23_MIDNIGHT',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '11_10_WAR',
  '11_11_LORD_OF_ADMIRALS',
  '11_12_CHARUM_HAKKOR',
  '11_13_FLOOD',
  '11_14_KNIGHTS',
  '11_15_JUSTICE',
  '11_16_CRYPTUM',
  '11_17_TERMINUS',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_22_THIS_IS_MY_RIFLE_THIS_IS_MY_GUN',
  '11_23_BROS_TO_THE_CLOSE',
  '11_24_EXPLORE_THE_FLOOR',
  '11_25_GIVE_HIM_THE_STICK',
  '11_26_CHIEF_SMASH',
  '11_27_YOURE_NO_CHIPS',
  '11_28_A_LONG_TIME_AGO',
  '11_30_HES_RUNNING_A_MARATHON',
  '12_0_TIGHTEN_UP_THE_GRAPHICS',
  '12_1_MIND_THE_GAP',
  '12_3_PACIFIST',
  '12_4_WRAITH_HUNTER',
  '12_5_CLOSE_QUARTERS_COMBAT',
  '12_6_THIS_SIDE_UP',
  '12_7_MORTARDOM',
  '12_8_ALL_ACCORDING_TO_PLAN',
  '12_9_BEACHHEAD',
  '12_11_NO-FLY_ZONE',
  '12_12_BRO_HAMMER',
  '12_13_DIGGING_UP_THE_PAST',
  '12_14_MISSING_LINK',
  '13_10_DIRTY_BIRD',
  '13_11_A_PREFERENCE_FOR_PAIN',
  '13_12_AFICIANDO',
  '13_13_NAKED_TYRANT',
  '13_14_DEVOTEE',
  '13_15_THRONE_OF_BONES',
  '13_16_BOOT_CAMP_HERO',
  '13_17_A_PILE_OF_MEDALS',
  '13_18_A_CRATE_OF_MEDALS',
  '13_19_A_HEAP_OF_MEDALS',
  '13_20_A_FEW_TRINKETS',
  '13_21_CHEST_OF_GOLD',
  '13_22_OH_THESE_BAUBLES?',
  '13_23_CHESTY_PULLER_STARTER_KIT',
  '13_24_MEDAL_MASTER_CHIEF',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_30_CANT_PUT_IT_DOWN',
  '14_0_UP_ALL_NIGHT',
  '14_2_CANT_GET_ENOUGH',
  '14_4_COMMITTED',
  '14_6_BIG_TIME_GAMER',
  '14_8_LONG_TIME_FAN',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '14_22_GREENHORN',
  '14_23_BIG_GREEN_STYLE',
  '14_24_TRAINING_WHEELS',
  '14_25_LIBRARIANS_GIFT',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '14_27_KING_OF_THE_CROWS',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '15_1_NO_STONE_UNTURNED',
  '4_17_GOAT_ROPED',
  '8_10_MONOPOLIZED',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '10_15_DEVASTATING',
  '11_29_YOURE_JOKING',
  '11_31_DESTINATION_VACATION',
  '15_3_OPERATION_COMPLETION',
  '15_4_DEPARTURE',
  '15_5_ARTIFACT',
  '15_6_CATHERINE',
  '15_7_DIDACTS_HAND',
  '15_8_MEMENTO_MORI',
  '15_9_SCATTERED',
  '15_10_INVASION',
  '15_11_EXPENDABLE',
  '15_12_KEY',
  '15_13_EXODUS',
  '15_14_FEET_FIRST',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '15_17_ICARUS',
  '15_19_ROSES_VS_VIOLETS',
  '15_20_NO_ONE_LEFT_BEHIND',
  '15_21_KNIGHT_IN_WHITE_ASSASSINATION',
  '15_22_WHAT_POWER_OUTAGE?',
  '15_23_NO_EASY_WAY_OUT',
  '16_4_NO_PAIN_NO_GAIN',
  '16_5_AIRBORNE',
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '16_23_LISTENER',
  '16_24_AN_EAR_FULL',
  '16_25_TUNED_IN',
  '16_26_DO_TELL',
  '16_27_HEAR_ME_OUT',
  '16_28_EAVESDROPPING',
  '16_29_AUDITOR',
  '16_30_OVERHEARD',
  '16_31_WIRETAPPING',
  '17_0_MONITORED',
  '17_1_HANG_ONTO_MY_WORDS',
  '17_2_GIVE_HEED',
  '17_3_GET_A_LOAD',
  '17_4_SNOOP_TROOP',
  '17_5_ALL_EARS',
  '17_6_EAR_BENDER',
  '17_7_PLUGGED_IN',
  '17_8_ATTENTIVE',
  '17_9_WATCHFUL',
  '17_10_OBSERVANT',
  '17_11_SCOUT',
  '17_12_ADVENTURER',
  '17_13_SPOTTER',
  '17_14_CONSIDERATE',
  '17_15_ON_YOUR_TOES',
  '17_16_ARCHIVIST',
  '17_17_YOUR_ATTENTION_PLEASE',
  '17_18_CATCHING_ON',
  '17_19_WISENING_UP',
  '17_20_AUDIOPHILE',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '17_25_JUNIOR_DETECTIVE',
  '17_26_INVESTIGATOR',
  '17_27_GUMSHOE',
  '17_28_BLOODHOUND',
  '17_29_PRIVATE_EYE',
  '17_30_SECRET_AGENT',
  '17_31_RECORD_STORE_OWNER',
  '18_0_TAYARI_PLAZA',
  '18_1_UPLIFT_RESERVE',
  '18_2_KIZINGO_BOULEVARD',
  '18_3_ONI_ALPHA_SITE',
  '18_4_NMPD_HQ',
  '18_5_KIKOWANI_STATION',
  '18_6_DATA_HIVE',
  '18_7_COASTAL_HIGHWAY',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '18_26_DEJA_VU',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '18_28_SAFETY_NOT_GUARANTEED',
  '18_29_TRIFECTA',
  '18_30_NEXT',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_0_ONE_GOT_AWAY',
  '19_1_LIKE_A_BROKEN_RECORD',
  '19_2_VANDALIZED',
  '19_3_SATURDAY_MORNING_CARTOON',
  '19_5_CATCH_EM_NAPPING',
  '19_6_STRONG_SILENT_TYPE',
  '19_7_BIP_BAP_BAM',
  '19_8_PINEAPPLE_EXPRESS',
  '19_9_FOWL_HUNT',
  '19_10_DOES_WHATEVER_A_SPARTAN_CAN',
  '19_11_FIREFLY',
  '19_12_BLAM_SAID_THE_LADY',
  '19_13_ONE_WAY_RIDE',
  '19_14_JUST_SAYIN_I_AINT_DEAD',
  '19_15_DEFERENCE_OF_DARKNESS',
  '19_16_THOSE_LEFT_BEHIND',
  '19_17_SHOOT_AND_SCOOT',
  '19_18_FAST_AND_LOW',
  '19_19_SHINY_',
  '19_20_BITS_AND_PIECES',
  '19_21_WHAT_ABOUT_THOSE_TANKS?',
  '19_22_TWO_PLACES_SAME_TIME',
  '19_23_XENOPHOBIA',
  '19_25_WERE_JUST_GETTING_STARTED',
  '19_26_NO_PICNIC',
  '19_27_PROTOCOL_DICTATES_ACTION',
  '19_28_I_NEED_A_WEAPON',
  '19_29_TO_WAR',
  '19_30_YOU_FLEW_PRETTY_GOOD',
  '19_31_INTO_THE_HOWLING_DARK',
  '20_0_DUST_AND_ECHOES',
  '20_1_THIS_IS_NOT_YOUR_GRAVE',
  '20_2_SEND_ME_OUT__WITH_A_BANG',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '20_30_KEEP_IT_CLEAN',
  '20_31_THEYVE_ALWAYS_BEEN_FASTER',
  '21_0_WAKE_UP_BUTTERCUP',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_2_YOUR_HERESY_WILL_STAY_YOUR_FEET',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '21_4_TANK_BEATS_EVERYTHING',
  '21_5_SWIFT_AND_SHALLOW',
  '21_6_FORZA_IN_THE_FALL',
  '21_7_HBORG_SPECIAL',
  '21_8_PLAY_US_A_SAD_SONG_CLAUDE',
  '21_9_WERE_GONNA_NEED_A_BIGGER_SHIP',
  '21_10_FRONT_PAGE_NEWS',
  '21_11_THE_HUMBLE_BEGINNINGS_OF_WILJAX_BRANTLEY',
  '21_12_DID_YOU_MAJOR_IN_MARINE_BIO?',
  '21_13_FLY_YOU_FOOLS',
  '21_14_THIS_IS_NOT_THE_GREATEST_LAB_IN_THE_WORLD',
  '21_15_HEY_YOU_DROPPED_THIS',
  '21_16_READ_HALSEYS_JOURNAL',
  '21_17_MEETING_OF_THE_(ARTIFICIAL)_MINDS',
  '21_18_RAMPANT_SPECULATION',
  '21_19_MINORITY_REPORT',
  '21_20_INFO_DUMP',
  '21_21_PAPER_TRAIL',
  '21_22_TERMINAL_CONDITIONS',
  '21_23_DIGITAL_DESTINIES',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_3_WHY_DO_THIS_TO_YOURSELF?',
  '22_4_REACH_HAS_BEEN_GOOD_TO_ME',
  '22_5_WHO_NEEDS_RED_FLAG?',
  '22_6_REMEMBER_REACH',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
  '22_8_ENEMIES_EVERYWHERE',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_12_LEFT_BEHIND',
  '22_13_ONE_FINAL_FIREFIGHT',
  '22_14_WHAT_ABOUT_BOB?',
  '22_15_JORGE_CANT_HAVE_ALL_THE_BIG_GUNS',
  '22_17_WORKERS_COMPENSATION',
  '22_22_BECAUSE_IT_WASNT_HARD_ENOUGH',
  '22_23_CLASSIC_COMPLETION',
  '22_25_COLLECTION_ELIGIBILITY_CONFIRMED',
  '22_26_MIND_THE_SKILL_GAP',
  '22_28_NEW_WAYS_TO_SAVE_A_BUCK',
  '22_16_IM_SORRY_DAVE',
  '22_24_CONFUSED_CALLOUTS',
  '22_27_EASY_TO_OVERLOOK',
];

export const VERSUS = [
  '1_1_JUST_GETTING_STARTED',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_12_THE_GUARDIANS_ARE_COMING',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_17_TEMPERED_BLADE',
  '1_18_FORGED_IN_FIRE',
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '1_26_YOUR_JOURNEY_BEGINS',
  '4_16_A_DAY_TO_REMEMBER',
  '8_8_BACK_AT_IT',
  '10_14_THIRD_TIMES_A_CHARM',
  '11_28_A_LONG_TIME_AGO',
  '12_2_BACK_IN_THE_DAY',
  '13_25_CONTENDER',
  '13_26_IN_IT_TO_WIN_IT',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_29_SHARPSHOOTER',
  '13_30_CANT_PUT_IT_DOWN',
  '13_31_BATTLE_HARDENED',
  '14_0_UP_ALL_NIGHT',
  '14_1_THERMOPYLAE',
  '14_2_CANT_GET_ENOUGH',
  '14_3_STRIKING_FEAR_IN_THEIR_HEARTS',
  '14_4_COMMITTED',
  '14_5_STEADY_AIM',
  '14_6_BIG_TIME_GAMER',
  '14_7_QUICK_TRIGGER_FINGER',
  '14_8_LONG_TIME_FAN',
  '14_9_MULTIPLAYER_CHAMPION',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '1_13_BITE_THE_HAND',
  '7_16_ANIMAL_HABITAT',
  '7_17_SID_GRAFFITI',
  '7_19_WHY_SO_SERIOUS',
  '7_20_WHY_AM_I_HERE',
  '7_31_GRAND_THEFT_HALO',
  '8_0_TOUR_OF_DUTY',
  '8_1_DOUBLE_DOWN',
  '8_2_WARDEN',
  '8_3_BLOWN_OUT_OF_THE_SKY',
  '8_4_DECORATED_WARRIOR',
  '8_5_NINJA_REDUX',
  '8_6_FLAMING_NINJA_ANNIVERSARY',
  '8_7_LEGEND_SLAYER',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '11_31_DESTINATION_VACATION',
  '12_15_EMINENT_DOMAIN',
  '12_17_GATE_YOUR_THIRST',
  '12_18_HIGH_ALTITUDE_THIRST',
  '12_19_INVADERS_REPELLED',
  '12_21_POWER_PLAY',
  '12_22_PUT_UP_YOUR_DUKES',
  '12_23_RED_VS_BLUE',
  '12_24_RULE_YOUR_THIRST',
  '12_25_SHIELDS_UP',
  '12_26_SHOOK_THE_HORNETS_NEST',
  '12_27_SKEET_SHOOTER',
  '12_28_STAYIN_ALIVE',
  '12_29_THE_GOOSE_IS_LOOSE',
  '12_30_THIRST_LOCKED_DOWN',
  '12_31_TOP_GUNGOOSE',
  '13_0_BLASTACULAR',
  '13_1_BLOODY_THIRSTY',
  '13_2_BOMBING_RUN',
  '13_3_COLD_AS_ICE',
  '13_4_COLD_FUSION',
  '13_5_COUNTER-SNIPED',
  '13_6_TRIPLE_THREAT',
  '13_7_WETWORK',
  '13_8_WORSHIP_YOUR_THIRST',
  '13_9_ZEALOT',
  '15_2_MVP',
  '15_24_NICE_WORK_DAWG',
  '15_25_AIM_FOR_THE_HEAD',
  '15_26_DIM_MAK',
  '15_27_DOMINATION',
  '15_28_DOUBLE_TROUBLE',
  '15_29_SPREE_MASTER',
  '15_30_CATCH_THIS',
  '15_31_PARTY_POOPER',
  '16_0_PLANTING_A_FLAG',
  '16_1_THE_TRUE_KING',
  '16_2_ROADKILL_RAMPAGE',
  '16_3_ROCK_AND_COIL_HIT_BACK',
  '16_5_AIRBORNE',
  '16_6_ASSEMBLY_SKULL',
  '16_7_ORBITAL_SKULL',
  '16_8_SANDBOX_SKULL',
  '16_9_CITADEL_SKULL',
  '16_10_HERETIC_SKULL',
  '16_11_LONGSHORE_SKULL',
  '16_12_BRAINPAN',
  '16_13_SIGHTSEEING',
  '16_14_CHECKING_OUT_YOUR_OPTIONS',
  '16_15_TOURIST',
  '16_16_WINDOW_SHOPPING',
  '16_17_WORLD_TRAVELER',
  '16_18_JUST_BROWSING',
  '16_19_GOING_INTERNATIONAL',
  '16_20_TASTE_TEST',
  '19_24_MONUMENTAL_THIRST',
  '22_9_NEGATIVE_GHOSTRIDER',
  '22_10_SKUNKED',
  '22_19_REQUIESCAT_IN_PACE',
  '22_20_RUN_AND_GUNNED',
  '22_21_GAME_BREAKER',
];

export const OFFLINE = [
  '1_14_MYSTERY_ACHIEVEMENT',
  '4_8_MEGG',
  '11_21_LONE_WOLF_LEGEND',
  '12_2_BACK_IN_THE_DAY',
  '12_16_GAME_MASTER',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
  '22_18_CAPACIOUS_CARTOGRAPHY',
];

export const ONLINE = [
  '9_31_ANNUAL',
  '1_13_BITE_THE_HAND',
  '8_2_WARDEN',
  '8_5_NINJA_REDUX',
  '8_6_FLAMING_NINJA_ANNIVERSARY',
  '12_25_SHIELDS_UP',
  '13_9_ZEALOT',
  '15_2_MVP',
  '15_27_DOMINATION',
  '15_28_DOUBLE_TROUBLE',
  '15_29_SPREE_MASTER',
  '15_30_CATCH_THIS',
  '15_31_PARTY_POOPER',
  '16_0_PLANTING_A_FLAG',
  '16_1_THE_TRUE_KING',
  '16_2_ROADKILL_RAMPAGE',
  '16_3_ROCK_AND_COIL_HIT_BACK',
  '18_26_DEJA_VU',
  '22_9_NEGATIVE_GHOSTRIDER',
  '22_10_SKUNKED',
];

export const ON_OR_OFFLINE = [
  '1_0_LIFE_STORY',
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_12_THE_GUARDIANS_ARE_COMING',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_17_TEMPERED_BLADE',
  '1_18_FORGED_IN_FIRE',
  '1_19_SAMPLE_PLATE',
  '1_20_I_DABBLE_IN_SLAYING',
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_25_LORE_MASTER',
  '1_26_YOUR_JOURNEY_BEGINS',
  '1_27_LASO_MASTER',
  '1_31_THE_SILENT_CARTOGRAPHER',
  '2_0_ASSAULT_ON_THE_CONTROL_ROOM',
  '1_28_PILLAR_OF_AUTUMN',
  '1_29_HALO',
  '1_30_TRUTH_AND_RECONCILIATION',
  '2_1_343_GUILTY_SPARK',
  '2_2_THE_LIBRARY',
  '2_3_TWO_BETRAYALS',
  '2_4_KEYES',
  '2_5_THE_MAW',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '2_28_ARRIVAL',
  '2_29_SACRIFICE',
  '2_30_SPLINTERS',
  '2_31_IDLE_HANDS',
  '3_0_HITCHHIKERS_MAY_BE_ESCAPING_CONVICTS',
  '3_1_BEARLY_CONTAINED',
  '3_2_QUIS_CUSTODIET_IPSOS_CUSTODES',
  '3_3_MEMORIES',
  '3_4_MIND_GAMES',
  '3_5_KILOTONS_OF_FUN',
  '3_6_DEAR_DIARY',
  '3_7_SKULLTAKER_HALO_CE_IRON',
  '3_8_SKULLTAKER_HALO_CE_MYTHIC',
  '3_9_SKULLTAKER_HALO_CE_BOOM',
  '3_10_SKULLTAKER_HALO_CE_FOREIGN',
  '3_11_SKULLTAKER_HALO_CE_FAMINE',
  '3_12_SKULLTAKER_HALO_CE_BANDANA',
  '3_13_SKULLTAKER_HALO_CE_FOG',
  '3_14_SKULLTAKER_HALO_CE_MALFUNCTION',
  '3_15_SKULLTAKER_HALO_CE_RECESSION',
  '3_16_SKULLTAKER_HALO_CE_BLACK_EYE',
  '3_17_SKULLTAKER_HALO_CE_EYE_PATCH',
  '3_18_SKULLTAKER_HALO_CE_PINATA',
  '3_19_SKULLTAKER_HALO_CE_GRUNT_BIRTHDAY_PARTY',
  '3_20_HEADHUNTER',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '3_24_OVERSHIELDS_ARE_FOR_SISSIES',
  '3_25_WALK_IT_OFF',
  '3_26_HOW_PEDESTRIAN',
  '3_27_THAT_JUST_HAPPENED',
  '3_28_LOOK_OUT_FOR_THE_LITTLE_GUYS',
  '3_29_LEAVE_IT_WHERE_IT_LAY',
  '3_30_TYING_UP_LOOSE_ENDS',
  '3_31_A_FINE_CHOICE',
  '4_0_EXCELLENT_TASTE',
  '4_1_LIKE_A_FINE_WINE',
  '4_2_YOU_ARE_THE_WEAPON',
  '4_3_MADRIGAL_DEBUT',
  '4_4_MY_BUDDY_CHIPS',
  '4_5_NO_SMOKING',
  '4_6_T-REX',
  '4_7_THIRSTY_GRUNT',
  '4_9_GET_ME_OUT_OF_HERE',
  '4_10_FEET_FIRMLY_PLANTED',
  '4_11_BEFORE_AND_AFTER',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '4_14_HES_UNSTOPPABLE',
  '4_15_THE_GOOD_THE_BAD_AND_THE_DEAD',
  '4_16_A_DAY_TO_REMEMBER',
  '4_18_CAIRO_STATION',
  '4_19_OUTSKIRTS',
  '4_20_METROPOLIS',
  '4_21_THE_ARBITER',
  '4_22_ORACLE',
  '4_23_DELTA_HALO',
  '4_24_REGRET',
  '4_25_SACRED_ICON',
  '4_26_QUARANTINE_ZONE',
  '4_27_GRAVEMIND',
  '4_28_UPRISING',
  '4_29_HIGH_CHARITY',
  '4_30_THE_GREAT_JOURNEY',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '5_27_CURIOUS',
  '5_28_INQUISITIVE',
  '5_29_EXAMINER',
  '5_30_INSPECTOR',
  '5_31_FASCINATED',
  '6_0_ENCHANTED',
  '6_1_ENAMORED',
  '6_2_DETERMINED',
  '6_3_DELIGHTED',
  '6_4_KNOWLEDGABLE',
  '6_5_SCHOLAR',
  '6_6_REVIEWER',
  '6_7_OBSESSED',
  '6_8_WALKING_ENCYCLOPEDIA',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_11_SKULLTAKER_HALO_2_BLIND',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '6_25_AND_SO_IT_BEGINS',
  '6_27_REGRETTABLE_TURN_OF_EVENTS',
  '6_28_THE_WILL_OF_THE_PROPHETS',
  '6_29_CHIEFTAIN_OF_THE_BRUTES',
  '6_30_DONT_MAKE_A_GIRL_A_PROMISE',
  '6_31_MY_WORK_IS_DONE',
  '7_0_I_LIKE_CRAZY',
  '7_1_PYRRHIC_SOLUTION',
  '7_2_TOYBOX',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '7_6_SECONDS',
  '7_7_CONNOISSEUR',
  '7_8_SIX_PEDALS_FOUR_DIRECTIONS',
  '7_9_BETCHA_CANT_STICK_IT',
  '7_10_COWARDLY_GRUNT',
  '7_11_SIEGE_OF_IVORY_TOWER',
  '7_12_THE_CHIPS_DUBBO?',
  '7_13_REX_SWORD',
  '7_14_10_MINUTES_TOO_EARLY',
  '7_15_HI_BEN',
  '7_18_E_E_E',
  '7_21_GOOOAAAAALLLL',
  '7_22_NEEDS_MORE_WHAMMY_BAR',
  '7_23_LIKE_PEANUT_BUTTER_AND_CHOCOLATE',
  '7_24_STARVED_FOR_SHIELDS',
  '7_25_COMMUTER',
  '7_26_HE_NEVER_GETS_ME_ANYTHING',
  '7_27_SCARAB_LORD',
  '7_28_SILENT_BUT_DEADLY',
  '7_29_DEMON',
  '7_30_GO_APE_SHIV',
  '8_8_BACK_AT_IT',
  '8_9_BACK_FOR_MORE',
  '8_11_LANDFALL',
  '8_12_HOLDOUT',
  '8_13_THE_ROAD',
  '8_14_ASSAULT',
  '8_15_CLEANSING',
  '8_16_REFUGE',
  '8_17_LAST_STAND',
  '8_18_THE_KEY',
  '8_19_RETURN',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '9_7_RISING_WATERS',
  '9_8_INUNDATION',
  '9_9_BEFORE_THE_FIRE',
  '9_10_TIPPING_POINT',
  '9_11_UNTO_DUST',
  '9_12_BABBLING_DEAD',
  '9_13_SWORD_AND_SHIELD',
  '9_14_THE_COINS_FAULT',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '9_28_BEGINS_WITH_A_SINGLE_STEP',
  '9_29_WHO_NEEDS_THEM?',
  '9_30_PHANTOM_HUNTER',
  '10_0_DIRGE_OF_MADRIGAL',
  '10_1_PRIMATE',
  '10_2_HAPLORRHINI',
  '10_3_CANT_KEEP_HIM_DOWN',
  '10_4_FLIPYAP',
  '10_5_HEADING_TO_HIS_DESTINY',
  '10_6_SOUNDS_FAMILIAR',
  '10_8_MILK_CARTON',
  '10_9_COOL_STORY_BRO',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '10_14_THIRD_TIMES_A_CHARM',
  '10_16_DAWN',
  '10_17_REQUIEM',
  '10_18_FORERUNNER',
  '10_19_INFINITY',
  '10_20_RECLAIMER',
  '10_21_SHUTDOWN',
  '10_22_COMPOSER',
  '10_23_MIDNIGHT',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '11_10_WAR',
  '11_11_LORD_OF_ADMIRALS',
  '11_12_CHARUM_HAKKOR',
  '11_13_FLOOD',
  '11_14_KNIGHTS',
  '11_15_JUSTICE',
  '11_16_CRYPTUM',
  '11_17_TERMINUS',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_22_THIS_IS_MY_RIFLE_THIS_IS_MY_GUN',
  '11_23_BROS_TO_THE_CLOSE',
  '11_24_EXPLORE_THE_FLOOR',
  '11_25_GIVE_HIM_THE_STICK',
  '11_26_CHIEF_SMASH',
  '11_27_YOURE_NO_CHIPS',
  '11_28_A_LONG_TIME_AGO',
  '11_30_HES_RUNNING_A_MARATHON',
  '12_0_TIGHTEN_UP_THE_GRAPHICS',
  '12_1_MIND_THE_GAP',
  '12_3_PACIFIST',
  '12_4_WRAITH_HUNTER',
  '12_5_CLOSE_QUARTERS_COMBAT',
  '12_6_THIS_SIDE_UP',
  '12_7_MORTARDOM',
  '12_8_ALL_ACCORDING_TO_PLAN',
  '12_9_BEACHHEAD',
  '12_10_THE_RETURN_OF_MEGG',
  '12_11_NO-FLY_ZONE',
  '12_12_BRO_HAMMER',
  '12_13_DIGGING_UP_THE_PAST',
  '12_14_MISSING_LINK',
  '13_10_DIRTY_BIRD',
  '13_11_A_PREFERENCE_FOR_PAIN',
  '13_12_AFICIANDO',
  '13_13_NAKED_TYRANT',
  '13_14_DEVOTEE',
  '13_15_THRONE_OF_BONES',
  '13_16_BOOT_CAMP_HERO',
  '13_17_A_PILE_OF_MEDALS',
  '13_18_A_CRATE_OF_MEDALS',
  '13_19_A_HEAP_OF_MEDALS',
  '13_20_A_FEW_TRINKETS',
  '13_21_CHEST_OF_GOLD',
  '13_22_OH_THESE_BAUBLES?',
  '13_23_CHESTY_PULLER_STARTER_KIT',
  '13_24_MEDAL_MASTER_CHIEF',
  '13_25_CONTENDER',
  '13_26_IN_IT_TO_WIN_IT',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_29_SHARPSHOOTER',
  '13_30_CANT_PUT_IT_DOWN',
  '13_31_BATTLE_HARDENED',
  '14_0_UP_ALL_NIGHT',
  '14_1_THERMOPYLAE',
  '14_2_CANT_GET_ENOUGH',
  '14_3_STRIKING_FEAR_IN_THEIR_HEARTS',
  '14_4_COMMITTED',
  '14_5_STEADY_AIM',
  '14_6_BIG_TIME_GAMER',
  '14_7_QUICK_TRIGGER_FINGER',
  '14_8_LONG_TIME_FAN',
  '14_9_MULTIPLAYER_CHAMPION',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '14_22_GREENHORN',
  '14_23_BIG_GREEN_STYLE',
  '14_24_TRAINING_WHEELS',
  '14_25_LIBRARIANS_GIFT',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '14_27_KING_OF_THE_CROWS',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '15_1_NO_STONE_UNTURNED',
  '4_17_GOAT_ROPED',
  '7_16_ANIMAL_HABITAT',
  '7_17_SID_GRAFFITI',
  '7_19_WHY_SO_SERIOUS',
  '7_20_WHY_AM_I_HERE',
  '7_31_GRAND_THEFT_HALO',
  '8_0_TOUR_OF_DUTY',
  '8_1_DOUBLE_DOWN',
  '8_3_BLOWN_OUT_OF_THE_SKY',
  '8_4_DECORATED_WARRIOR',
  '8_7_LEGEND_SLAYER',
  '8_10_MONOPOLIZED',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '10_15_DEVASTATING',
  '11_29_YOURE_JOKING',
  '11_31_DESTINATION_VACATION',
  '12_15_EMINENT_DOMAIN',
  '12_17_GATE_YOUR_THIRST',
  '12_18_HIGH_ALTITUDE_THIRST',
  '12_19_INVADERS_REPELLED',
  '12_20_MASTER_FORGER',
  '12_21_POWER_PLAY',
  '12_22_PUT_UP_YOUR_DUKES',
  '12_23_RED_VS_BLUE',
  '12_24_RULE_YOUR_THIRST',
  '12_26_SHOOK_THE_HORNETS_NEST',
  '12_27_SKEET_SHOOTER',
  '12_28_STAYIN_ALIVE',
  '12_29_THE_GOOSE_IS_LOOSE',
  '12_30_THIRST_LOCKED_DOWN',
  '12_31_TOP_GUNGOOSE',
  '13_0_BLASTACULAR',
  '13_1_BLOODY_THIRSTY',
  '13_2_BOMBING_RUN',
  '13_3_COLD_AS_ICE',
  '13_4_COLD_FUSION',
  '13_5_COUNTER-SNIPED',
  '13_6_TRIPLE_THREAT',
  '13_7_WETWORK',
  '13_8_WORSHIP_YOUR_THIRST',
  '15_3_OPERATION_COMPLETION',
  '15_4_DEPARTURE',
  '15_5_ARTIFACT',
  '15_6_CATHERINE',
  '15_7_DIDACTS_HAND',
  '15_8_MEMENTO_MORI',
  '15_9_SCATTERED',
  '15_10_INVASION',
  '15_11_EXPENDABLE',
  '15_12_KEY',
  '15_13_EXODUS',
  '15_14_FEET_FIRST',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '15_17_ICARUS',
  '15_18_SMOOTH_OPERATOR',
  '15_19_ROSES_VS_VIOLETS',
  '15_20_NO_ONE_LEFT_BEHIND',
  '15_21_KNIGHT_IN_WHITE_ASSASSINATION',
  '15_22_WHAT_POWER_OUTAGE?',
  '15_23_NO_EASY_WAY_OUT',
  '15_24_NICE_WORK_DAWG',
  '15_25_AIM_FOR_THE_HEAD',
  '15_26_DIM_MAK',
  '16_4_NO_PAIN_NO_GAIN',
  '16_5_AIRBORNE',
  '16_6_ASSEMBLY_SKULL',
  '16_7_ORBITAL_SKULL',
  '16_8_SANDBOX_SKULL',
  '16_9_CITADEL_SKULL',
  '16_10_HERETIC_SKULL',
  '16_11_LONGSHORE_SKULL',
  '16_12_BRAINPAN',
  '16_13_SIGHTSEEING',
  '16_14_CHECKING_OUT_YOUR_OPTIONS',
  '16_15_TOURIST',
  '16_16_WINDOW_SHOPPING',
  '16_17_WORLD_TRAVELER',
  '16_18_JUST_BROWSING',
  '16_19_GOING_INTERNATIONAL',
  '16_20_TASTE_TEST',
  '19_24_MONUMENTAL_THIRST',
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '16_23_LISTENER',
  '16_24_AN_EAR_FULL',
  '16_25_TUNED_IN',
  '16_26_DO_TELL',
  '16_27_HEAR_ME_OUT',
  '16_28_EAVESDROPPING',
  '16_29_AUDITOR',
  '16_30_OVERHEARD',
  '16_31_WIRETAPPING',
  '17_0_MONITORED',
  '17_1_HANG_ONTO_MY_WORDS',
  '17_2_GIVE_HEED',
  '17_3_GET_A_LOAD',
  '17_4_SNOOP_TROOP',
  '17_5_ALL_EARS',
  '17_6_EAR_BENDER',
  '17_7_PLUGGED_IN',
  '17_8_ATTENTIVE',
  '17_9_WATCHFUL',
  '17_10_OBSERVANT',
  '17_11_SCOUT',
  '17_12_ADVENTURER',
  '17_13_SPOTTER',
  '17_14_CONSIDERATE',
  '17_15_ON_YOUR_TOES',
  '17_16_ARCHIVIST',
  '17_17_YOUR_ATTENTION_PLEASE',
  '17_18_CATCHING_ON',
  '17_19_WISENING_UP',
  '17_20_AUDIOPHILE',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '17_25_JUNIOR_DETECTIVE',
  '17_26_INVESTIGATOR',
  '17_27_GUMSHOE',
  '17_28_BLOODHOUND',
  '17_29_PRIVATE_EYE',
  '17_30_SECRET_AGENT',
  '17_31_RECORD_STORE_OWNER',
  '18_0_TAYARI_PLAZA',
  '18_1_UPLIFT_RESERVE',
  '18_2_KIZINGO_BOULEVARD',
  '18_3_ONI_ALPHA_SITE',
  '18_4_NMPD_HQ',
  '18_5_KIKOWANI_STATION',
  '18_6_DATA_HIVE',
  '18_7_COASTAL_HIGHWAY',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '18_28_SAFETY_NOT_GUARANTEED',
  '18_29_TRIFECTA',
  '18_30_NEXT',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_0_ONE_GOT_AWAY',
  '19_1_LIKE_A_BROKEN_RECORD',
  '19_2_VANDALIZED',
  '19_3_SATURDAY_MORNING_CARTOON',
  '19_4_CLASSIC',
  '19_5_CATCH_EM_NAPPING',
  '19_6_STRONG_SILENT_TYPE',
  '19_7_BIP_BAP_BAM',
  '19_8_PINEAPPLE_EXPRESS',
  '19_9_FOWL_HUNT',
  '19_10_DOES_WHATEVER_A_SPARTAN_CAN',
  '19_11_FIREFLY',
  '19_12_BLAM_SAID_THE_LADY',
  '19_13_ONE_WAY_RIDE',
  '19_14_JUST_SAYIN_I_AINT_DEAD',
  '19_15_DEFERENCE_OF_DARKNESS',
  '19_16_THOSE_LEFT_BEHIND',
  '19_17_SHOOT_AND_SCOOT',
  '19_18_FAST_AND_LOW',
  '19_19_SHINY_',
  '19_20_BITS_AND_PIECES',
  '19_21_WHAT_ABOUT_THOSE_TANKS?',
  '19_22_TWO_PLACES_SAME_TIME',
  '19_23_XENOPHOBIA',
  '19_25_WERE_JUST_GETTING_STARTED',
  '19_26_NO_PICNIC',
  '19_27_PROTOCOL_DICTATES_ACTION',
  '19_28_I_NEED_A_WEAPON',
  '19_29_TO_WAR',
  '19_30_YOU_FLEW_PRETTY_GOOD',
  '19_31_INTO_THE_HOWLING_DARK',
  '20_0_DUST_AND_ECHOES',
  '20_1_THIS_IS_NOT_YOUR_GRAVE',
  '20_2_SEND_ME_OUT__WITH_A_BANG',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '20_30_KEEP_IT_CLEAN',
  '20_31_THEYVE_ALWAYS_BEEN_FASTER',
  '21_0_WAKE_UP_BUTTERCUP',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_2_YOUR_HERESY_WILL_STAY_YOUR_FEET',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '21_4_TANK_BEATS_EVERYTHING',
  '21_5_SWIFT_AND_SHALLOW',
  '21_6_FORZA_IN_THE_FALL',
  '21_7_HBORG_SPECIAL',
  '21_8_PLAY_US_A_SAD_SONG_CLAUDE',
  '21_9_WERE_GONNA_NEED_A_BIGGER_SHIP',
  '21_10_FRONT_PAGE_NEWS',
  '21_11_THE_HUMBLE_BEGINNINGS_OF_WILJAX_BRANTLEY',
  '21_12_DID_YOU_MAJOR_IN_MARINE_BIO?',
  '21_13_FLY_YOU_FOOLS',
  '21_14_THIS_IS_NOT_THE_GREATEST_LAB_IN_THE_WORLD',
  '21_15_HEY_YOU_DROPPED_THIS',
  '21_16_READ_HALSEYS_JOURNAL',
  '21_17_MEETING_OF_THE_(ARTIFICIAL)_MINDS',
  '21_18_RAMPANT_SPECULATION',
  '21_19_MINORITY_REPORT',
  '21_20_INFO_DUMP',
  '21_21_PAPER_TRAIL',
  '21_22_TERMINAL_CONDITIONS',
  '21_23_DIGITAL_DESTINIES',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_3_WHY_DO_THIS_TO_YOURSELF?',
  '22_4_REACH_HAS_BEEN_GOOD_TO_ME',
  '22_5_WHO_NEEDS_RED_FLAG?',
  '22_6_REMEMBER_REACH',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
  '22_8_ENEMIES_EVERYWHERE',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_12_LEFT_BEHIND',
  '22_13_ONE_FINAL_FIREFIGHT',
  '22_14_WHAT_ABOUT_BOB?',
  '22_15_JORGE_CANT_HAVE_ALL_THE_BIG_GUNS',
  '22_17_WORKERS_COMPENSATION',
  '22_22_BECAUSE_IT_WASNT_HARD_ENOUGH',
  '22_23_CLASSIC_COMPLETION',
  '22_25_COLLECTION_ELIGIBILITY_CONFIRMED',
  '22_26_MIND_THE_SKILL_GAP',
  '22_28_NEW_WAYS_TO_SAVE_A_BUCK',
  '22_16_IM_SORRY_DAVE',
  '22_19_REQUIESCAT_IN_PACE',
  '22_20_RUN_AND_GUNNED',
  '22_21_GAME_BREAKER',
  '22_24_CONFUSED_CALLOUTS',
  '22_27_EASY_TO_OVERLOOK',
];

export const COLLECTABLE = [
  '1_25_LORE_MASTER',
  '2_28_ARRIVAL',
  '2_29_SACRIFICE',
  '2_30_SPLINTERS',
  '2_31_IDLE_HANDS',
  '3_0_HITCHHIKERS_MAY_BE_ESCAPING_CONVICTS',
  '3_1_BEARLY_CONTAINED',
  '3_2_QUIS_CUSTODIET_IPSOS_CUSTODES',
  '3_3_MEMORIES',
  '3_4_MIND_GAMES',
  '3_5_KILOTONS_OF_FUN',
  '3_6_DEAR_DIARY',
  '3_7_SKULLTAKER_HALO_CE_IRON',
  '3_8_SKULLTAKER_HALO_CE_MYTHIC',
  '3_9_SKULLTAKER_HALO_CE_BOOM',
  '3_10_SKULLTAKER_HALO_CE_FOREIGN',
  '3_11_SKULLTAKER_HALO_CE_FAMINE',
  '3_12_SKULLTAKER_HALO_CE_BANDANA',
  '3_13_SKULLTAKER_HALO_CE_FOG',
  '3_14_SKULLTAKER_HALO_CE_MALFUNCTION',
  '3_15_SKULLTAKER_HALO_CE_RECESSION',
  '3_16_SKULLTAKER_HALO_CE_BLACK_EYE',
  '3_17_SKULLTAKER_HALO_CE_EYE_PATCH',
  '3_18_SKULLTAKER_HALO_CE_PINATA',
  '3_19_SKULLTAKER_HALO_CE_GRUNT_BIRTHDAY_PARTY',
  '3_20_HEADHUNTER',
  '4_3_MADRIGAL_DEBUT',
  '4_5_NO_SMOKING',
  '4_6_T-REX',
  '4_7_THIRSTY_GRUNT',
  '4_8_MEGG',
  '5_27_CURIOUS',
  '5_28_INQUISITIVE',
  '5_29_EXAMINER',
  '5_30_INSPECTOR',
  '5_31_FASCINATED',
  '6_0_ENCHANTED',
  '6_1_ENAMORED',
  '6_2_DETERMINED',
  '6_3_DELIGHTED',
  '6_4_KNOWLEDGABLE',
  '6_5_SCHOLAR',
  '6_6_REVIEWER',
  '6_7_OBSESSED',
  '6_8_WALKING_ENCYCLOPEDIA',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_11_SKULLTAKER_HALO_2_BLIND',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '6_25_AND_SO_IT_BEGINS',
  '6_27_REGRETTABLE_TURN_OF_EVENTS',
  '6_28_THE_WILL_OF_THE_PROPHETS',
  '6_29_CHIEFTAIN_OF_THE_BRUTES',
  '6_30_DONT_MAKE_A_GIRL_A_PROMISE',
  '6_31_MY_WORK_IS_DONE',
  '7_0_I_LIKE_CRAZY',
  '7_1_PYRRHIC_SOLUTION',
  '7_2_TOYBOX',
  '7_10_COWARDLY_GRUNT',
  '7_11_SIEGE_OF_IVORY_TOWER',
  '7_13_REX_SWORD',
  '7_14_10_MINUTES_TOO_EARLY',
  '7_15_HI_BEN',
  '7_18_E_E_E',
  '7_21_GOOOAAAAALLLL',
  '7_27_SCARAB_LORD',
  '9_7_RISING_WATERS',
  '9_8_INUNDATION',
  '9_9_BEFORE_THE_FIRE',
  '9_10_TIPPING_POINT',
  '9_11_UNTO_DUST',
  '9_12_BABBLING_DEAD',
  '9_13_SWORD_AND_SHIELD',
  '9_14_THE_COINS_FAULT',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '10_0_DIRGE_OF_MADRIGAL',
  '10_1_PRIMATE',
  '10_2_HAPLORRHINI',
  '10_5_HEADING_TO_HIS_DESTINY',
  '10_6_SOUNDS_FAMILIAR',
  '10_8_MILK_CARTON',
  '10_9_COOL_STORY_BRO',
  '11_10_WAR',
  '11_11_LORD_OF_ADMIRALS',
  '11_12_CHARUM_HAKKOR',
  '11_13_FLOOD',
  '11_14_KNIGHTS',
  '11_15_JUSTICE',
  '11_16_CRYPTUM',
  '11_17_TERMINUS',
  '12_13_DIGGING_UP_THE_PAST',
  '13_15_THRONE_OF_BONES',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '15_1_NO_STONE_UNTURNED',
  '7_16_ANIMAL_HABITAT',
  '7_17_SID_GRAFFITI',
  '7_19_WHY_SO_SERIOUS',
  '7_20_WHY_AM_I_HERE',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '11_31_DESTINATION_VACATION',
  '12_17_GATE_YOUR_THIRST',
  '12_18_HIGH_ALTITUDE_THIRST',
  '12_24_RULE_YOUR_THIRST',
  '12_30_THIRST_LOCKED_DOWN',
  '13_0_BLASTACULAR',
  '13_1_BLOODY_THIRSTY',
  '13_8_WORSHIP_YOUR_THIRST',
  '15_19_ROSES_VS_VIOLETS',
  '16_6_ASSEMBLY_SKULL',
  '16_7_ORBITAL_SKULL',
  '16_8_SANDBOX_SKULL',
  '16_9_CITADEL_SKULL',
  '16_10_HERETIC_SKULL',
  '16_11_LONGSHORE_SKULL',
  '16_12_BRAINPAN',
  '19_24_MONUMENTAL_THIRST',
  '16_23_LISTENER',
  '16_24_AN_EAR_FULL',
  '16_25_TUNED_IN',
  '16_26_DO_TELL',
  '16_27_HEAR_ME_OUT',
  '16_28_EAVESDROPPING',
  '16_29_AUDITOR',
  '16_30_OVERHEARD',
  '16_31_WIRETAPPING',
  '17_0_MONITORED',
  '17_1_HANG_ONTO_MY_WORDS',
  '17_2_GIVE_HEED',
  '17_3_GET_A_LOAD',
  '17_4_SNOOP_TROOP',
  '17_5_ALL_EARS',
  '17_6_EAR_BENDER',
  '17_7_PLUGGED_IN',
  '17_8_ATTENTIVE',
  '17_9_WATCHFUL',
  '17_10_OBSERVANT',
  '17_11_SCOUT',
  '17_12_ADVENTURER',
  '17_13_SPOTTER',
  '17_14_CONSIDERATE',
  '17_15_ON_YOUR_TOES',
  '17_16_ARCHIVIST',
  '17_17_YOUR_ATTENTION_PLEASE',
  '17_18_CATCHING_ON',
  '17_19_WISENING_UP',
  '17_20_AUDIOPHILE',
  '17_31_RECORD_STORE_OWNER',
  '19_0_ONE_GOT_AWAY',
  '19_1_LIKE_A_BROKEN_RECORD',
  '19_2_VANDALIZED',
  '19_3_SATURDAY_MORNING_CARTOON',
  '21_6_FORZA_IN_THE_FALL',
  '21_7_HBORG_SPECIAL',
  '21_8_PLAY_US_A_SAD_SONG_CLAUDE',
  '21_9_WERE_GONNA_NEED_A_BIGGER_SHIP',
  '21_10_FRONT_PAGE_NEWS',
  '21_11_THE_HUMBLE_BEGINNINGS_OF_WILJAX_BRANTLEY',
  '21_12_DID_YOU_MAJOR_IN_MARINE_BIO?',
  '21_13_FLY_YOU_FOOLS',
  '21_14_THIS_IS_NOT_THE_GREATEST_LAB_IN_THE_WORLD',
  '21_15_HEY_YOU_DROPPED_THIS',
  '21_16_READ_HALSEYS_JOURNAL',
  '21_17_MEETING_OF_THE_(ARTIFICIAL)_MINDS',
  '21_18_RAMPANT_SPECULATION',
  '21_19_MINORITY_REPORT',
  '21_20_INFO_DUMP',
  '21_21_PAPER_TRAIL',
  '21_22_TERMINAL_CONDITIONS',
  '21_23_DIGITAL_DESTINIES',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_14_WHAT_ABOUT_BOB?',
  '22_15_JORGE_CANT_HAVE_ALL_THE_BIG_GUNS',
  '22_25_COLLECTION_ELIGIBILITY_CONFIRMED',
  '22_26_MIND_THE_SKILL_GAP',
  '22_28_NEW_WAYS_TO_SAVE_A_BUCK',
  '22_16_IM_SORRY_DAVE',
  '22_24_CONFUSED_CALLOUTS',
  '22_27_EASY_TO_OVERLOOK',
];

export const STORY = [
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_26_YOUR_JOURNEY_BEGINS',
  '1_31_THE_SILENT_CARTOGRAPHER',
  '2_0_ASSAULT_ON_THE_CONTROL_ROOM',
  '1_28_PILLAR_OF_AUTUMN',
  '1_29_HALO',
  '1_30_TRUTH_AND_RECONCILIATION',
  '2_1_343_GUILTY_SPARK',
  '2_2_THE_LIBRARY',
  '2_3_TWO_BETRAYALS',
  '2_4_KEYES',
  '2_5_THE_MAW',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '4_18_CAIRO_STATION',
  '4_19_OUTSKIRTS',
  '4_20_METROPOLIS',
  '4_21_THE_ARBITER',
  '4_22_ORACLE',
  '4_23_DELTA_HALO',
  '4_24_REGRET',
  '4_25_SACRED_ICON',
  '4_26_QUARANTINE_ZONE',
  '4_27_GRAVEMIND',
  '4_28_UPRISING',
  '4_29_HIGH_CHARITY',
  '4_30_THE_GREAT_JOURNEY',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '8_11_LANDFALL',
  '8_12_HOLDOUT',
  '8_13_THE_ROAD',
  '8_14_ASSAULT',
  '8_15_CLEANSING',
  '8_16_REFUGE',
  '8_17_LAST_STAND',
  '8_18_THE_KEY',
  '8_19_RETURN',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '10_16_DAWN',
  '10_17_REQUIEM',
  '10_18_FORERUNNER',
  '10_19_INFINITY',
  '10_20_RECLAIMER',
  '10_21_SHUTDOWN',
  '10_22_COMPOSER',
  '10_23_MIDNIGHT',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_21_LONE_WOLF_LEGEND',
  '12_12_BRO_HAMMER',
  '13_10_DIRTY_BIRD',
  '14_22_GREENHORN',
  '14_23_BIG_GREEN_STYLE',
  '14_24_TRAINING_WHEELS',
  '14_25_LIBRARIANS_GIFT',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '15_18_SMOOTH_OPERATOR',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '17_25_JUNIOR_DETECTIVE',
  '17_26_INVESTIGATOR',
  '17_27_GUMSHOE',
  '17_28_BLOODHOUND',
  '17_29_PRIVATE_EYE',
  '17_30_SECRET_AGENT',
  '18_0_TAYARI_PLAZA',
  '18_1_UPLIFT_RESERVE',
  '18_2_KIZINGO_BOULEVARD',
  '18_3_ONI_ALPHA_SITE',
  '18_4_NMPD_HQ',
  '18_5_KIKOWANI_STATION',
  '18_6_DATA_HIVE',
  '18_7_COASTAL_HIGHWAY',
  '19_22_TWO_PLACES_SAME_TIME',
  '19_25_WERE_JUST_GETTING_STARTED',
  '19_27_PROTOCOL_DICTATES_ACTION',
  '19_28_I_NEED_A_WEAPON',
  '19_29_TO_WAR',
  '19_30_YOU_FLEW_PRETTY_GOOD',
  '19_31_INTO_THE_HOWLING_DARK',
  '20_0_DUST_AND_ECHOES',
  '20_1_THIS_IS_NOT_YOUR_GRAVE',
  '20_2_SEND_ME_OUT__WITH_A_BANG',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
];

export const DIFFICULTY = [
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_27_LASO_MASTER',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '3_24_OVERSHIELDS_ARE_FOR_SISSIES',
  '3_25_WALK_IT_OFF',
  '3_27_THAT_JUST_HAPPENED',
  '3_28_LOOK_OUT_FOR_THE_LITTLE_GUYS',
  '3_29_LEAVE_IT_WHERE_IT_LAY',
  '3_30_TYING_UP_LOOSE_ENDS',
  '4_1_LIKE_A_FINE_WINE',
  '4_8_MEGG',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '4_14_HES_UNSTOPPABLE',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '7_23_LIKE_PEANUT_BUTTER_AND_CHOCOLATE',
  '7_24_STARVED_FOR_SHIELDS',
  '7_25_COMMUTER',
  '7_26_HE_NEVER_GETS_ME_ANYTHING',
  '7_29_DEMON',
  '8_9_BACK_FOR_MORE',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '9_29_WHO_NEEDS_THEM?',
  '9_31_ANNUAL',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_21_LONE_WOLF_LEGEND',
  '11_22_THIS_IS_MY_RIFLE_THIS_IS_MY_GUN',
  '11_23_BROS_TO_THE_CLOSE',
  '11_24_EXPLORE_THE_FLOOR',
  '11_27_YOURE_NO_CHIPS',
  '11_30_HES_RUNNING_A_MARATHON',
  '12_1_MIND_THE_GAP',
  '12_7_MORTARDOM',
  '12_9_BEACHHEAD',
  '12_10_THE_RETURN_OF_MEGG',
  '12_12_BRO_HAMMER',
  '13_11_A_PREFERENCE_FOR_PAIN',
  '13_13_NAKED_TYRANT',
  '13_15_THRONE_OF_BONES',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '4_17_GOAT_ROPED',
  '8_10_MONOPOLIZED',
  '10_15_DEVASTATING',
  '11_29_YOURE_JOKING',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '15_17_ICARUS',
  '15_18_SMOOTH_OPERATOR',
  '15_20_NO_ONE_LEFT_BEHIND',
  '15_22_WHAT_POWER_OUTAGE?',
  '15_23_NO_EASY_WAY_OUT',
  '16_4_NO_PAIN_NO_GAIN',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '18_26_DEJA_VU',
  '18_28_SAFETY_NOT_GUARANTEED',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_4_CLASSIC',
  '19_13_ONE_WAY_RIDE',
  '19_14_JUST_SAYIN_I_AINT_DEAD',
  '19_17_SHOOT_AND_SCOOT',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
  '20_31_THEYVE_ALWAYS_BEEN_FASTER',
  '21_0_WAKE_UP_BUTTERCUP',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_2_YOUR_HERESY_WILL_STAY_YOUR_FEET',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '21_4_TANK_BEATS_EVERYTHING',
  '21_5_SWIFT_AND_SHALLOW',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_3_WHY_DO_THIS_TO_YOURSELF?',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
  '22_8_ENEMIES_EVERYWHERE',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_22_BECAUSE_IT_WASNT_HARD_ENOUGH',
];

export const STACKABLE = [
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '7_3_WARRIOR',
  '7_4_HERO',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '14_22_GREENHORN',
  '14_23_BIG_GREEN_STYLE',
  '14_24_TRAINING_WHEELS',
  '14_25_LIBRARIANS_GIFT',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
];

export const CUMULATIVE = [
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_17_TEMPERED_BLADE',
  '1_18_FORGED_IN_FIRE',
  '4_15_THE_GOOD_THE_BAD_AND_THE_DEAD',
  '9_30_PHANTOM_HUNTER',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '13_17_A_PILE_OF_MEDALS',
  '13_18_A_CRATE_OF_MEDALS',
  '13_19_A_HEAP_OF_MEDALS',
  '13_20_A_FEW_TRINKETS',
  '13_22_OH_THESE_BAUBLES?',
  '13_23_CHESTY_PULLER_STARTER_KIT',
  '13_24_MEDAL_MASTER_CHIEF',
  '13_25_CONTENDER',
  '13_26_IN_IT_TO_WIN_IT',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_29_SHARPSHOOTER',
  '13_30_CANT_PUT_IT_DOWN',
  '13_31_BATTLE_HARDENED',
  '14_0_UP_ALL_NIGHT',
  '14_1_THERMOPYLAE',
  '14_2_CANT_GET_ENOUGH',
  '14_3_STRIKING_FEAR_IN_THEIR_HEARTS',
  '14_4_COMMITTED',
  '14_5_STEADY_AIM',
  '14_6_BIG_TIME_GAMER',
  '14_7_QUICK_TRIGGER_FINGER',
  '14_8_LONG_TIME_FAN',
  '14_9_MULTIPLAYER_CHAMPION',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '8_5_NINJA_REDUX',
  '10_13_ELDER_SIGNS',
  '12_16_GAME_MASTER',
  '12_20_MASTER_FORGER',
  '12_22_PUT_UP_YOUR_DUKES',
  '12_23_RED_VS_BLUE',
  '12_26_SHOOK_THE_HORNETS_NEST',
  '12_27_SKEET_SHOOTER',
  '12_29_THE_GOOSE_IS_LOOSE',
  '13_9_ZEALOT',
  '15_24_NICE_WORK_DAWG',
  '15_25_AIM_FOR_THE_HEAD',
  '15_26_DIM_MAK',
  '15_27_DOMINATION',
  '15_28_DOUBLE_TROUBLE',
  '15_29_SPREE_MASTER',
  '15_30_CATCH_THIS',
  '15_31_PARTY_POOPER',
  '16_0_PLANTING_A_FLAG',
  '16_1_THE_TRUE_KING',
  '16_2_ROADKILL_RAMPAGE',
  '16_3_ROCK_AND_COIL_HIT_BACK',
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '19_5_CATCH_EM_NAPPING',
  '19_18_FAST_AND_LOW',
  '19_20_BITS_AND_PIECES',
  '19_21_WHAT_ABOUT_THOSE_TANKS?',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '22_19_REQUIESCAT_IN_PACE',
  '22_20_RUN_AND_GUNNED',
  '22_21_GAME_BREAKER',
];

export const PLAYERS_3 = ['13_6_TRIPLE_THREAT', '15_24_NICE_WORK_DAWG'];

export const PLAYERS_4 = [
  '9_31_ANNUAL',
  '12_2_BACK_IN_THE_DAY',
  '1_13_BITE_THE_HAND',
  '8_2_WARDEN',
  '8_5_NINJA_REDUX',
  '8_6_FLAMING_NINJA_ANNIVERSARY',
  '12_25_SHIELDS_UP',
  '13_9_ZEALOT',
  '15_2_MVP',
  '15_27_DOMINATION',
  '15_28_DOUBLE_TROUBLE',
  '15_29_SPREE_MASTER',
  '15_30_CATCH_THIS',
  '15_31_PARTY_POOPER',
  '16_0_PLANTING_A_FLAG',
  '16_1_THE_TRUE_KING',
  '16_2_ROADKILL_RAMPAGE',
  '16_3_ROCK_AND_COIL_HIT_BACK',
  '18_26_DEJA_VU',
  '22_9_NEGATIVE_GHOSTRIDER',
];

export const VIRAL = [
  '1_13_BITE_THE_HAND',
  '8_6_FLAMING_NINJA_ANNIVERSARY',
  '8_7_LEGEND_SLAYER',
];

export const TIME_DATE = [
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '4_16_A_DAY_TO_REMEMBER',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '8_8_BACK_AT_IT',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '10_14_THIRD_TIMES_A_CHARM',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '11_28_A_LONG_TIME_AGO',
  '4_17_GOAT_ROPED',
  '8_10_MONOPOLIZED',
  '10_13_ELDER_SIGNS',
  '10_15_DEVASTATING',
  '11_29_YOURE_JOKING',
  '11_31_DESTINATION_VACATION',
  '12_15_EMINENT_DOMAIN',
  '13_7_WETWORK',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_7_BIP_BAP_BAM',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
  '21_0_WAKE_UP_BUTTERCUP',
  '22_6_REMEMBER_REACH',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
];

export const HALO_CE = [
  '1_31_THE_SILENT_CARTOGRAPHER',
  '2_0_ASSAULT_ON_THE_CONTROL_ROOM',
  '1_28_PILLAR_OF_AUTUMN',
  '1_29_HALO',
  '1_30_TRUTH_AND_RECONCILIATION',
  '2_1_343_GUILTY_SPARK',
  '2_2_THE_LIBRARY',
  '2_3_TWO_BETRAYALS',
  '2_4_KEYES',
  '2_5_THE_MAW',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '2_28_ARRIVAL',
  '2_29_SACRIFICE',
  '2_30_SPLINTERS',
  '2_31_IDLE_HANDS',
  '3_0_HITCHHIKERS_MAY_BE_ESCAPING_CONVICTS',
  '3_1_BEARLY_CONTAINED',
  '3_2_QUIS_CUSTODIET_IPSOS_CUSTODES',
  '3_3_MEMORIES',
  '3_4_MIND_GAMES',
  '3_5_KILOTONS_OF_FUN',
  '3_6_DEAR_DIARY',
  '3_7_SKULLTAKER_HALO_CE_IRON',
  '3_8_SKULLTAKER_HALO_CE_MYTHIC',
  '3_9_SKULLTAKER_HALO_CE_BOOM',
  '3_10_SKULLTAKER_HALO_CE_FOREIGN',
  '3_11_SKULLTAKER_HALO_CE_FAMINE',
  '3_12_SKULLTAKER_HALO_CE_BANDANA',
  '3_13_SKULLTAKER_HALO_CE_FOG',
  '3_14_SKULLTAKER_HALO_CE_MALFUNCTION',
  '3_15_SKULLTAKER_HALO_CE_RECESSION',
  '3_16_SKULLTAKER_HALO_CE_BLACK_EYE',
  '3_17_SKULLTAKER_HALO_CE_EYE_PATCH',
  '3_18_SKULLTAKER_HALO_CE_PINATA',
  '3_19_SKULLTAKER_HALO_CE_GRUNT_BIRTHDAY_PARTY',
  '3_20_HEADHUNTER',
  '3_21_BIRTH_OF_A_SPARTAN',
  '3_22_BELIEVE_IN_A_HERO',
  '3_23_LIVING_LEGEND',
  '3_24_OVERSHIELDS_ARE_FOR_SISSIES',
  '3_25_WALK_IT_OFF',
  '3_26_HOW_PEDESTRIAN',
  '3_27_THAT_JUST_HAPPENED',
  '3_28_LOOK_OUT_FOR_THE_LITTLE_GUYS',
  '3_29_LEAVE_IT_WHERE_IT_LAY',
  '3_30_TYING_UP_LOOSE_ENDS',
  '3_31_A_FINE_CHOICE',
  '4_0_EXCELLENT_TASTE',
  '4_1_LIKE_A_FINE_WINE',
  '4_2_YOU_ARE_THE_WEAPON',
  '4_3_MADRIGAL_DEBUT',
  '4_4_MY_BUDDY_CHIPS',
  '4_5_NO_SMOKING',
  '4_6_T-REX',
  '4_7_THIRSTY_GRUNT',
  '4_8_MEGG',
  '4_9_GET_ME_OUT_OF_HERE',
  '4_10_FEET_FIRMLY_PLANTED',
  '4_11_BEFORE_AND_AFTER',
  '4_14_HES_UNSTOPPABLE',
  '4_16_A_DAY_TO_REMEMBER',
  '12_2_BACK_IN_THE_DAY',
  '12_3_PACIFIST',
  '12_4_WRAITH_HUNTER',
  '12_5_CLOSE_QUARTERS_COMBAT',
  '12_6_THIS_SIDE_UP',
  '12_8_ALL_ACCORDING_TO_PLAN',
  '12_9_BEACHHEAD',
  '12_11_NO-FLY_ZONE',
  '14_22_GREENHORN',
  '4_17_GOAT_ROPED',
  '16_5_AIRBORNE',
  '16_13_SIGHTSEEING',
  '16_14_CHECKING_OUT_YOUR_OPTIONS',
];

export const HALO_2 = [
  '4_18_CAIRO_STATION',
  '4_19_OUTSKIRTS',
  '4_20_METROPOLIS',
  '4_21_THE_ARBITER',
  '4_22_ORACLE',
  '4_23_DELTA_HALO',
  '4_24_REGRET',
  '4_25_SACRED_ICON',
  '4_26_QUARANTINE_ZONE',
  '4_27_GRAVEMIND',
  '4_28_UPRISING',
  '4_29_HIGH_CHARITY',
  '4_30_THE_GREAT_JOURNEY',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '5_27_CURIOUS',
  '5_28_INQUISITIVE',
  '5_29_EXAMINER',
  '5_30_INSPECTOR',
  '5_31_FASCINATED',
  '6_0_ENCHANTED',
  '6_1_ENAMORED',
  '6_2_DETERMINED',
  '6_3_DELIGHTED',
  '6_4_KNOWLEDGABLE',
  '6_5_SCHOLAR',
  '6_6_REVIEWER',
  '6_7_OBSESSED',
  '6_8_WALKING_ENCYCLOPEDIA',
  '6_9_SKULLTAKER_HALO_2_THATS_JUSTWRONG',
  '6_10_SKULLTAKER_HALO_2_THUNDERSTORM',
  '6_11_SKULLTAKER_HALO_2_BLIND',
  '6_12_SKULLTAKER_HALO_2_IWHBYD',
  '6_13_SKULLTAKER_HALO_2_CATCH',
  '6_14_SKULLTAKER_HALO_2_GRUNT_BIRTHDAY_PARTY',
  '6_15_SKULLTAKER_HALO_2_FAMINE',
  '6_16_SKULLTAKER_HALO_2_ENVY',
  '6_17_SKULLTAKER_HALO_2_ASSASSINS',
  '6_18_SKULLTAKER_HALO_2_MYTHIC',
  '6_19_SKULLTAKER_HALO_2_SPUTNIK',
  '6_20_SKULLTAKER_HALO_2_ANGER',
  '6_21_SKULLTAKER_HALO_2_GHOST',
  '6_22_SKULLTAKER_HALO_2_IRON',
  '6_23_SKULLTAKER_HALO_2_BLACK_EYE',
  '6_24_TROPHY_COLLECTOR',
  '6_25_AND_SO_IT_BEGINS',
  '6_27_REGRETTABLE_TURN_OF_EVENTS',
  '6_28_THE_WILL_OF_THE_PROPHETS',
  '6_29_CHIEFTAIN_OF_THE_BRUTES',
  '6_30_DONT_MAKE_A_GIRL_A_PROMISE',
  '6_31_MY_WORK_IS_DONE',
  '7_0_I_LIKE_CRAZY',
  '7_1_PYRRHIC_SOLUTION',
  '7_2_TOYBOX',
  '7_3_WARRIOR',
  '7_4_HERO',
  '7_5_LEGENDARY_ANNIVERSARY',
  '7_6_SECONDS',
  '7_7_CONNOISSEUR',
  '7_8_SIX_PEDALS_FOUR_DIRECTIONS',
  '7_9_BETCHA_CANT_STICK_IT',
  '7_10_COWARDLY_GRUNT',
  '7_11_SIEGE_OF_IVORY_TOWER',
  '7_12_THE_CHIPS_DUBBO?',
  '7_13_REX_SWORD',
  '7_14_10_MINUTES_TOO_EARLY',
  '7_15_HI_BEN',
  '7_18_E_E_E',
  '7_21_GOOOAAAAALLLL',
  '7_22_NEEDS_MORE_WHAMMY_BAR',
  '7_23_LIKE_PEANUT_BUTTER_AND_CHOCOLATE',
  '7_24_STARVED_FOR_SHIELDS',
  '7_25_COMMUTER',
  '7_26_HE_NEVER_GETS_ME_ANYTHING',
  '7_27_SCARAB_LORD',
  '7_28_SILENT_BUT_DEADLY',
  '7_29_DEMON',
  '7_30_GO_APE_SHIV',
  '8_8_BACK_AT_IT',
  '8_9_BACK_FOR_MORE',
  '11_30_HES_RUNNING_A_MARATHON',
  '12_0_TIGHTEN_UP_THE_GRAPHICS',
  '12_10_THE_RETURN_OF_MEGG',
  '13_10_DIRTY_BIRD',
  '14_23_BIG_GREEN_STYLE',
  '7_16_ANIMAL_HABITAT',
  '7_17_SID_GRAFFITI',
  '7_19_WHY_SO_SERIOUS',
  '7_20_WHY_AM_I_HERE',
  '7_31_GRAND_THEFT_HALO',
  '8_0_TOUR_OF_DUTY',
  '8_1_DOUBLE_DOWN',
  '8_2_WARDEN',
  '8_3_BLOWN_OUT_OF_THE_SKY',
  '8_4_DECORATED_WARRIOR',
  '8_5_NINJA_REDUX',
  '8_6_FLAMING_NINJA_ANNIVERSARY',
  '8_7_LEGEND_SLAYER',
  '8_10_MONOPOLIZED',
  '11_31_DESTINATION_VACATION',
  '12_15_EMINENT_DOMAIN',
  '12_17_GATE_YOUR_THIRST',
  '12_18_HIGH_ALTITUDE_THIRST',
  '12_19_INVADERS_REPELLED',
  '12_21_POWER_PLAY',
  '12_22_PUT_UP_YOUR_DUKES',
  '12_23_RED_VS_BLUE',
  '12_24_RULE_YOUR_THIRST',
  '12_25_SHIELDS_UP',
  '12_26_SHOOK_THE_HORNETS_NEST',
  '12_27_SKEET_SHOOTER',
  '12_28_STAYIN_ALIVE',
  '12_29_THE_GOOSE_IS_LOOSE',
  '12_30_THIRST_LOCKED_DOWN',
  '12_31_TOP_GUNGOOSE',
  '13_0_BLASTACULAR',
  '13_1_BLOODY_THIRSTY',
  '13_2_BOMBING_RUN',
  '13_3_COLD_AS_ICE',
  '13_4_COLD_FUSION',
  '13_5_COUNTER-SNIPED',
  '13_6_TRIPLE_THREAT',
  '13_7_WETWORK',
  '13_8_WORSHIP_YOUR_THIRST',
  '13_9_ZEALOT',
  '15_2_MVP',
  '16_2_ROADKILL_RAMPAGE',
  '16_3_ROCK_AND_COIL_HIT_BACK',
  '16_15_TOURIST',
  '16_16_WINDOW_SHOPPING',
  '19_24_MONUMENTAL_THIRST',
];

export const HALO_3 = [
  '8_11_LANDFALL',
  '8_12_HOLDOUT',
  '8_13_THE_ROAD',
  '8_14_ASSAULT',
  '8_15_CLEANSING',
  '8_16_REFUGE',
  '8_17_LAST_STAND',
  '8_18_THE_KEY',
  '8_19_RETURN',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '9_7_RISING_WATERS',
  '9_8_INUNDATION',
  '9_9_BEFORE_THE_FIRE',
  '9_10_TIPPING_POINT',
  '9_11_UNTO_DUST',
  '9_12_BABBLING_DEAD',
  '9_13_SWORD_AND_SHIELD',
  '9_14_THE_COINS_FAULT',
  '9_15_WHISPERS_ACROSS_THE_GALAXY',
  '9_16_SKULLTAKER_HALO_3_IRON',
  '9_17_SKULLTAKER_HALO_3_BLACK_EYE',
  '9_18_SKULLTAKER_HALO_3_TOUGH_LUCK',
  '9_19_SKULLTAKER_HALO_3_CATCH',
  '9_20_SKULLTAKER_HALO_3_FOG',
  '9_21_SKULLTAKER_HALO_3_FAMINE',
  '9_22_SKULLTAKER_HALO_3_THUNDERSTORM',
  '9_23_SKULLTAKER_HALO_3_TILT',
  '9_24_SKULLTAKER_HALO_3_MYTHIC',
  '9_25_PROPHETS_BANE',
  '9_26_WITH_YOUR_SHIELD_OR_ON_IT',
  '9_27_FINISHED_THE_FIGHT',
  '9_28_BEGINS_WITH_A_SINGLE_STEP',
  '9_29_WHO_NEEDS_THEM?',
  '9_30_PHANTOM_HUNTER',
  '9_31_ANNUAL',
  '10_0_DIRGE_OF_MADRIGAL',
  '10_1_PRIMATE',
  '10_2_HAPLORRHINI',
  '10_3_CANT_KEEP_HIM_DOWN',
  '10_4_FLIPYAP',
  '10_5_HEADING_TO_HIS_DESTINY',
  '10_6_SOUNDS_FAMILIAR',
  '10_8_MILK_CARTON',
  '10_9_COOL_STORY_BRO',
  '10_14_THIRD_TIMES_A_CHARM',
  '12_1_MIND_THE_GAP',
  '12_14_MISSING_LINK',
  '13_11_A_PREFERENCE_FOR_PAIN',
  '13_12_AFICIANDO',
  '13_16_BOOT_CAMP_HERO',
  '14_24_TRAINING_WHEELS',
  '14_27_KING_OF_THE_CROWS',
  '14_28_WITCH_DOCTOR',
  '14_29_SKULLTAKER_HALO_3_BLIND',
  '14_30_SKULLTAKER_HALO_3_GRUNT_BIRTHDAY_PARTY',
  '14_31_SKULLTAKER_HALO_3_COWBELL',
  '15_0_SKULLTAKER_HALO_3_IWHBYD',
  '15_1_NO_STONE_UNTURNED',
  '10_7_HIPPO_HERO',
  '10_10_MAN_ON_THE_MOON',
  '10_13_ELDER_SIGNS',
  '10_15_DEVASTATING',
  '16_6_ASSEMBLY_SKULL',
  '16_7_ORBITAL_SKULL',
  '16_8_SANDBOX_SKULL',
  '16_9_CITADEL_SKULL',
  '16_10_HERETIC_SKULL',
  '16_11_LONGSHORE_SKULL',
  '16_12_BRAINPAN',
  '16_17_WORLD_TRAVELER',
  '16_18_JUST_BROWSING',
];

export const HALO_4 = [
  '10_16_DAWN',
  '10_17_REQUIEM',
  '10_18_FORERUNNER',
  '10_19_INFINITY',
  '10_20_RECLAIMER',
  '10_21_SHUTDOWN',
  '10_22_COMPOSER',
  '10_23_MIDNIGHT',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '11_10_WAR',
  '11_11_LORD_OF_ADMIRALS',
  '11_12_CHARUM_HAKKOR',
  '11_13_FLOOD',
  '11_14_KNIGHTS',
  '11_15_JUSTICE',
  '11_16_CRYPTUM',
  '11_17_TERMINUS',
  '11_18_WAKE_UP_JOHN',
  '11_19_I_NEED_A_HERO',
  '11_20_THE_LEGEND_OF_117',
  '11_21_LONE_WOLF_LEGEND',
  '11_22_THIS_IS_MY_RIFLE_THIS_IS_MY_GUN',
  '11_23_BROS_TO_THE_CLOSE',
  '11_24_EXPLORE_THE_FLOOR',
  '11_25_GIVE_HIM_THE_STICK',
  '11_26_CHIEF_SMASH',
  '11_27_YOURE_NO_CHIPS',
  '11_28_A_LONG_TIME_AGO',
  '12_7_MORTARDOM',
  '12_13_DIGGING_UP_THE_PAST',
  '13_13_NAKED_TYRANT',
  '13_14_DEVOTEE',
  '13_21_CHEST_OF_GOLD',
  '14_25_LIBRARIANS_GIFT',
  '11_29_YOURE_JOKING',
  '15_3_OPERATION_COMPLETION',
  '15_4_DEPARTURE',
  '15_5_ARTIFACT',
  '15_6_CATHERINE',
  '15_7_DIDACTS_HAND',
  '15_8_MEMENTO_MORI',
  '15_9_SCATTERED',
  '15_10_INVASION',
  '15_11_EXPENDABLE',
  '15_12_KEY',
  '15_13_EXODUS',
  '15_14_FEET_FIRST',
  '15_15_TERM_OF_ENLISTMENT',
  '15_16_HAZARD_PAY',
  '15_17_ICARUS',
  '15_18_SMOOTH_OPERATOR',
  '15_19_ROSES_VS_VIOLETS',
  '15_20_NO_ONE_LEFT_BEHIND',
  '15_21_KNIGHT_IN_WHITE_ASSASSINATION',
  '15_22_WHAT_POWER_OUTAGE?',
  '15_23_NO_EASY_WAY_OUT',
  '16_19_GOING_INTERNATIONAL',
  '16_20_TASTE_TEST',
];

export const HALO_ODST = [
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '16_23_LISTENER',
  '16_24_AN_EAR_FULL',
  '16_25_TUNED_IN',
  '16_26_DO_TELL',
  '16_27_HEAR_ME_OUT',
  '16_28_EAVESDROPPING',
  '16_29_AUDITOR',
  '16_30_OVERHEARD',
  '16_31_WIRETAPPING',
  '17_0_MONITORED',
  '17_1_HANG_ONTO_MY_WORDS',
  '17_2_GIVE_HEED',
  '17_3_GET_A_LOAD',
  '17_4_SNOOP_TROOP',
  '17_5_ALL_EARS',
  '17_6_EAR_BENDER',
  '17_7_PLUGGED_IN',
  '17_8_ATTENTIVE',
  '17_9_WATCHFUL',
  '17_10_OBSERVANT',
  '17_11_SCOUT',
  '17_12_ADVENTURER',
  '17_13_SPOTTER',
  '17_14_CONSIDERATE',
  '17_15_ON_YOUR_TOES',
  '17_16_ARCHIVIST',
  '17_17_YOUR_ATTENTION_PLEASE',
  '17_18_CATCHING_ON',
  '17_19_WISENING_UP',
  '17_20_AUDIOPHILE',
  '17_21_ROOKIE',
  '17_22_URBAN_WARFARE_BADGE',
  '17_23_FIRST_IN_LAST_OUT',
  '17_24_MEAN_STREETS_OF_NEW_MOMBASA',
  '17_25_JUNIOR_DETECTIVE',
  '17_26_INVESTIGATOR',
  '17_27_GUMSHOE',
  '17_28_BLOODHOUND',
  '17_29_PRIVATE_EYE',
  '17_30_SECRET_AGENT',
  '17_31_RECORD_STORE_OWNER',
  '18_0_TAYARI_PLAZA',
  '18_1_UPLIFT_RESERVE',
  '18_2_KIZINGO_BOULEVARD',
  '18_3_ONI_ALPHA_SITE',
  '18_4_NMPD_HQ',
  '18_5_KIKOWANI_STATION',
  '18_6_DATA_HIVE',
  '18_7_COASTAL_HIGHWAY',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '18_26_DEJA_VU',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '18_28_SAFETY_NOT_GUARANTEED',
  '18_29_TRIFECTA',
  '18_30_NEXT',
  '18_31_NAGATO_MAKES_MOVING_EASY',
  '19_0_ONE_GOT_AWAY',
  '19_1_LIKE_A_BROKEN_RECORD',
  '19_2_VANDALIZED',
  '19_3_SATURDAY_MORNING_CARTOON',
  '19_4_CLASSIC',
  '19_5_CATCH_EM_NAPPING',
  '19_6_STRONG_SILENT_TYPE',
  '19_7_BIP_BAP_BAM',
  '19_8_PINEAPPLE_EXPRESS',
  '19_9_FOWL_HUNT',
  '19_10_DOES_WHATEVER_A_SPARTAN_CAN',
  '19_11_FIREFLY',
  '19_12_BLAM_SAID_THE_LADY',
  '19_13_ONE_WAY_RIDE',
  '19_14_JUST_SAYIN_I_AINT_DEAD',
  '19_15_DEFERENCE_OF_DARKNESS',
  '19_16_THOSE_LEFT_BEHIND',
  '19_17_SHOOT_AND_SCOOT',
  '19_18_FAST_AND_LOW',
  '19_19_SHINY_',
  '19_20_BITS_AND_PIECES',
  '19_21_WHAT_ABOUT_THOSE_TANKS?',
  '19_22_TWO_PLACES_SAME_TIME',
  '19_23_XENOPHOBIA',
];

export const HALO_REACH = [
  '19_25_WERE_JUST_GETTING_STARTED',
  '19_26_NO_PICNIC',
  '19_27_PROTOCOL_DICTATES_ACTION',
  '19_28_I_NEED_A_WEAPON',
  '19_29_TO_WAR',
  '19_30_YOU_FLEW_PRETTY_GOOD',
  '19_31_INTO_THE_HOWLING_DARK',
  '20_0_DUST_AND_ECHOES',
  '20_1_THIS_IS_NOT_YOUR_GRAVE',
  '20_2_SEND_ME_OUT__WITH_A_BANG',
  '20_3_WELCOME_TO_REACH',
  '20_4_THE_SOLDIER_WE_NEED_YOU_TO_BE',
  '20_5_FOLKS_NEED_HEROES',
  '20_6_GODS_MUST_BE_STRONG',
  '20_7_A_MONUMENT_TO_ALL_YOUR_SINS',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '20_30_KEEP_IT_CLEAN',
  '20_31_THEYVE_ALWAYS_BEEN_FASTER',
  '21_0_WAKE_UP_BUTTERCUP',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_2_YOUR_HERESY_WILL_STAY_YOUR_FEET',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '21_4_TANK_BEATS_EVERYTHING',
  '21_5_SWIFT_AND_SHALLOW',
  '21_6_FORZA_IN_THE_FALL',
  '21_7_HBORG_SPECIAL',
  '21_8_PLAY_US_A_SAD_SONG_CLAUDE',
  '21_9_WERE_GONNA_NEED_A_BIGGER_SHIP',
  '21_10_FRONT_PAGE_NEWS',
  '21_11_THE_HUMBLE_BEGINNINGS_OF_WILJAX_BRANTLEY',
  '21_12_DID_YOU_MAJOR_IN_MARINE_BIO?',
  '21_13_FLY_YOU_FOOLS',
  '21_14_THIS_IS_NOT_THE_GREATEST_LAB_IN_THE_WORLD',
  '21_15_HEY_YOU_DROPPED_THIS',
  '21_16_READ_HALSEYS_JOURNAL',
  '21_17_MEETING_OF_THE_(ARTIFICIAL)_MINDS',
  '21_18_RAMPANT_SPECULATION',
  '21_19_MINORITY_REPORT',
  '21_20_INFO_DUMP',
  '21_21_PAPER_TRAIL',
  '21_22_TERMINAL_CONDITIONS',
  '21_23_DIGITAL_DESTINIES',
  '21_24_UNRELIABLE_NARRATOR',
  '21_25_WHAT_DOES_IT_ALL_MEAN?',
  '21_26_UNSOLVED_MYSTERIES',
  '21_27_MEDDLING_AND_MADNESS',
  '21_28_THE_ANSWER_IS_SIMPLE',
  '21_29_OF_PATIENCE_AND_PARTIALITY',
  '21_30_CURIOUS_COMPOSITION',
  '21_31_SILENT_SHEPHERDS',
  '22_0_ASSEMBLED_THE_MAJORITY',
  '22_1_YES_NO_YOU_DECIDE',
  '22_2_CANONICAL_CONUNDRUM',
  '22_3_WHY_DO_THIS_TO_YOURSELF?',
  '22_4_REACH_HAS_BEEN_GOOD_TO_ME',
  '22_5_WHO_NEEDS_RED_FLAG?',
  '22_6_REMEMBER_REACH',
  '22_7_KEEP_YOUR_FOOT_ON_THE_PEDROGAS',
  '22_8_ENEMIES_EVERYWHERE',
  '22_11_CLEAR_AND_PRESENT_DANGER',
  '22_12_LEFT_BEHIND',
  '22_13_ONE_FINAL_FIREFIGHT',
  '22_14_WHAT_ABOUT_BOB?',
  '22_15_JORGE_CANT_HAVE_ALL_THE_BIG_GUNS',
  '22_17_WORKERS_COMPENSATION',
  '22_22_BECAUSE_IT_WASNT_HARD_ENOUGH',
  '22_23_CLASSIC_COMPLETION',
  '22_25_COLLECTION_ELIGIBILITY_CONFIRMED',
  '22_26_MIND_THE_SKILL_GAP',
  '22_28_NEW_WAYS_TO_SAVE_A_BUCK',
  '22_9_NEGATIVE_GHOSTRIDER',
  '22_10_SKUNKED',
  '22_16_IM_SORRY_DAVE',
  '22_18_CAPACIOUS_CARTOGRAPHY',
  '22_19_REQUIESCAT_IN_PACE',
  '22_20_RUN_AND_GUNNED',
  '22_21_GAME_BREAKER',
  '22_24_CONFUSED_CALLOUTS',
  '22_27_EASY_TO_OVERLOOK',
];

export const ANY_GAME = [
  '1_0_LIFE_STORY',
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '1_11_MEDAL_COMPLETIONIST',
  '1_12_THE_GUARDIANS_ARE_COMING',
  '1_14_MYSTERY_ACHIEVEMENT',
  '1_15_WHERE_AM_I?',
  '1_16_I_GOT_THIS',
  '1_17_TEMPERED_BLADE',
  '1_18_FORGED_IN_FIRE',
  '1_19_SAMPLE_PLATE',
  '1_20_I_DABBLE_IN_SLAYING',
  '1_21_STICK_WITH_IT_A_LITTLE_LONGER',
  '1_22_VETERAN',
  '1_23_WAR_HERO',
  '1_24_LEGEND',
  '1_25_LORE_MASTER',
  '1_26_YOUR_JOURNEY_BEGINS',
  '1_27_LASO_MASTER',
  '4_12_STANDARD_OPERATING_BROCEDURE',
  '4_13_BROVERSHIELD',
  '4_15_THE_GOOD_THE_BAD_AND_THE_DEAD',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '12_12_BRO_HAMMER',
  '13_15_THRONE_OF_BONES',
  '13_17_A_PILE_OF_MEDALS',
  '13_18_A_CRATE_OF_MEDALS',
  '13_19_A_HEAP_OF_MEDALS',
  '13_20_A_FEW_TRINKETS',
  '13_22_OH_THESE_BAUBLES?',
  '13_23_CHESTY_PULLER_STARTER_KIT',
  '13_24_MEDAL_MASTER_CHIEF',
  '13_25_CONTENDER',
  '13_26_IN_IT_TO_WIN_IT',
  '13_27_GAME_ON',
  '13_28_BONUS_LEVEL',
  '13_29_SHARPSHOOTER',
  '13_30_CANT_PUT_IT_DOWN',
  '13_31_BATTLE_HARDENED',
  '14_0_UP_ALL_NIGHT',
  '14_1_THERMOPYLAE',
  '14_2_CANT_GET_ENOUGH',
  '14_3_STRIKING_FEAR_IN_THEIR_HEARTS',
  '14_4_COMMITTED',
  '14_5_STEADY_AIM',
  '14_6_BIG_TIME_GAMER',
  '14_7_QUICK_TRIGGER_FINGER',
  '14_8_LONG_TIME_FAN',
  '14_9_MULTIPLAYER_CHAMPION',
  '14_10_THE_LONG_HAUL',
  '14_11_JUST_A_TASTE',
  '14_12_ALL_OUT_OF_BUBBLEGUM',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '14_26_HERALD_OF_THE_RECLAMATION',
  '1_13_BITE_THE_HAND',
  '12_16_GAME_MASTER',
  '12_20_MASTER_FORGER',
  '15_24_NICE_WORK_DAWG',
  '15_25_AIM_FOR_THE_HEAD',
  '15_26_DIM_MAK',
  '15_27_DOMINATION',
  '15_28_DOUBLE_TROUBLE',
  '15_29_SPREE_MASTER',
  '15_30_CATCH_THIS',
  '15_31_PARTY_POOPER',
  '16_0_PLANTING_A_FLAG',
  '16_1_THE_TRUE_KING',
  '16_4_NO_PAIN_NO_GAIN',
];

export const PAR_TIME = [
  '2_6_DIDNT_LIKE_THIS_SHIP_ANYWAYS',
  '2_7_FLYOVER_COUNTRY',
  '2_8_YOU_CANT_HANDLE_THE_TRUTH',
  '2_9_HIT_THE_BEACH',
  '2_10_WOULD_ITVE_KILLED_YOU_TO_TAKE_THE_ELEVATOR',
  '2_11_LIGHTNING_IN_A_BOTTLE',
  '2_12_TLDR',
  '2_13_WHISTLE_STOP_TOUR',
  '2_14_SCURTY_BUMP',
  '2_15_ALL_YOU_CAN_EAT',
  '2_16_DID_SOMEBODY_SAY',
  '4_31_DONT_HANGAR_AROUND',
  '5_0_ROOFTOP_RUNNER',
  '5_1_SPEEDING_TICKET',
  '5_2_YOLO_STRATS',
  '5_3_REED_THE_STRATEGY',
  '5_4_SO_YOU_DONT_WANT_THE_TANK',
  '5_5_I_REGRET_NOTHING',
  '5_6_TERMINAL_VELOCITY',
  '5_7_HOT_ZONE',
  '5_8_FORCE_OF_WILL',
  '5_9_NO_MANS_LAND',
  '5_10_FEELING_CHARITABLE',
  '5_11_ARE_WE_THERE_YET',
  '5_12_GOING_NOWHERE_FAST',
  '8_20_SPEEDY_ONE_ONE_SEVEN',
  '8_21_TIME_SHIFT',
  '8_22_HIGHWAY_ROBBERY',
  '8_23_LIGHTNING_STRIKE',
  '8_24_DAMBUSTER',
  '8_25_WAYPOINT',
  '8_26_SCARAB_SLIDE',
  '8_27_MAKING_HISTORY',
  '8_28_GO_CHIEF_GUN_IT',
  '8_29_SPEED_DEMON',
  '10_24_PIECE_OF_CAKE',
  '10_25_INTERLOPER',
  '10_26_RAGING_IN_THE_DARK',
  '10_27_OUROBOROS',
  '10_28_GIMME_THAT',
  '10_29_SHUT_DOWN_EVERYTHING',
  '10_30_SYMPHONY_OF_PAIN',
  '10_31_COUNTDOWN',
  '11_0_EASY_AS_ONE_TWO_THREE',
  '18_8_BILL_PAST_DUE',
  '18_9_FLOOR_IT',
  '18_10_AN_ARTISTS_CANVAS',
  '18_11_A_PLEASANT_TRIP',
  '18_12_FIRST_STOP_ANYWHERE_BUT_HERE',
  '18_13_FEET_DONT_FAIL_ME_NOW',
  '18_14_OVERTAKEN_BY_EVENTS',
  '18_15_DOUBLE_TIME',
  '18_16_CANT_STOP_HERE_THIS_IS_BRUTE_COUNTRY',
  '20_8_WINTER_URGENCY',
  '20_9_JUST_LIKE_STORMING_A_CASTLE',
  '20_10_NOW_JUST_YOU_GUTA_MINUTE',
  '20_11_GENEROUS_TIPPER',
  '20_12_SABRE_METRICS',
  '20_13_QUICKER_THAN_READING_THE_BOOK',
  '20_14_ONE_FOR_THE_RECORD_BOOKS',
  '20_15_SPEEDY_DELIVERY',
  '20_16_WAIT_HOWD_THE_SHIP_GET_DOWN_HERE?',
  '20_17_FAST_FALL',
];

export const PAR_SCORE = [
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '14_27_KING_OF_THE_CROWS',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
];

export const KILL_COUNT = [
  '1_1_JUST_GETTING_STARTED',
  '1_2_BALAHOS_MOST_WANTED',
  '1_3_THE_ONE_PERCENT',
  '1_4_SPOILSPORT',
  '1_5_GOING_BANANAS',
  '1_6_HUNTERS_HUNTED',
  '1_7_CHECKMATE',
  '1_8_I_WAS_WONDERING_WHAT_WOULD_BREAK_FIRST',
  '1_9_PEST_CONTROL',
  '1_10_THANKS_A_KILLION',
  '7_28_SILENT_BUT_DEADLY',
  '8_5_NINJA_REDUX',
  '10_11_DELICIOUS_BRAINS',
  '10_12_ZOMBIE_REPELLER',
  '11_26_CHIEF_SMASH',
  '12_19_INVADERS_REPELLED',
  '14_13_FOE_HAMMER',
  '14_14_GRUNTAGEDDON',
  '14_15_WERE_IT_SO_EASY',
  '14_16_SCAVENGER_HUNT',
  '14_17_DANKEY_KANG',
  '14_18_WYRMSLAYER',
  '14_19_REMOVE_THE_BISHOPS_FROM_THE_BOARD',
  '14_20_KNIGHTBANE',
  '14_21_DOGCATCHER',
  '16_21_NAUGHTY_NAUGHTY',
  '16_22_WELLMAYBE_ONE_OR_TWO',
  '19_5_CATCH_EM_NAPPING',
  '19_8_PINEAPPLE_EXPRESS',
  '19_9_FOWL_HUNT',
  '20_28_TVAOAN_TERMINATOR',
  '20_29_CHOL_VONS_NOT_GONNA_LIKE_THIS',
  '20_30_KEEP_IT_CLEAN',
  '21_1_TWO_CORPSES_IN_ONE_GRAVE',
  '21_3_I_DIDNT_TRAIN_TO_BE_A_PILOT',
  '22_21_GAME_BREAKER',
];

export const SPECIFIC_DATE = [
  '4_16_A_DAY_TO_REMEMBER',
  '8_8_BACK_AT_IT',
  '10_14_THIRD_TIMES_A_CHARM',
  '11_28_A_LONG_TIME_AGO',
  '18_27_DO_YOU_REMEMBER_WHEN_WE_MET',
  '22_6_REMEMBER_REACH',
];

export const SCORE = [
  '2_17_PILLAR_OF_AWESOME',
  '2_18_GIANT_HULA_HOOP',
  '2_19_YOU_REALLY_CANT_HANDLE_THE_TRUTH',
  '2_20_CHARTED',
  '2_21_VETRO_STRIKE',
  '2_22_CIRCUIT_BREAKER',
  '2_23_READING_ROOM',
  '2_24_IT_WAS_INEVITABLE',
  '2_25_HEADSTRONG',
  '2_26_CONSUMPTION_JUNCTION',
  '2_27_OVERACHIEVER',
  '5_13_BOMB_SQUAD',
  '5_14_OBJECTIVE_SECURED',
  '5_15_RIPPLE_FIRE',
  '5_16_HERESY_WILL_NOT_STAY_MY_FEET',
  '5_17_LEAVE_NONE_ALIVE',
  '5_18_BOUNDLESS',
  '5_19_FISTS_OF_FURY',
  '5_20_GUIDED_TOUR',
  '5_21_THE_DOCTOR_IS_IN',
  '5_22_OFFICIAL_BUSINESS',
  '5_23_THE_HYPE_IS_REAL',
  '5_24_SCORCHED_EARTH',
  '5_25_FREQUENT_FLYER_MILES',
  '5_26_ARCADE_OWNER',
  '8_30_GUERILLA',
  '8_31_CAVALIER',
  '9_0_ASKAR',
  '9_1_EXTERMINATOR',
  '9_2_RANGER',
  '9_3_VANGUARD',
  '9_4_ORPHEUS',
  '9_5_TORCHBEARER',
  '9_6_BETTER_WITH_AGE',
  '11_1_GUNSLINGER',
  '11_2_DARK_FORTRESS',
  '11_3_SHADOW_OF_SUNDERED_STAR',
  '11_4_JUNGLE_WARFARE',
  '11_5_LIBRARIANS_PET',
  '11_6_EMERGENCY_SHUTDOWN',
  '11_7_HEAD_BANGING',
  '11_8_WITCHING_HOUR',
  '11_9_YOU_HAD_IT_COMING',
  '14_27_KING_OF_THE_CROWS',
  '18_17_SURVEY_SAYS',
  '18_18_DONT_STRAIN_YOUR_METAPHORS',
  '18_19_SON_OF_A_GUN',
  '18_20_I_LOVE_MY_JOB',
  '18_21_POUR_IT_ON',
  '18_22_SQUID_JACKPOT',
  '18_23_MAGIC_TOUCH',
  '18_24_THIS_OLIPHANT_HAS_NO_BRAKES',
  '18_25_THE_MARINE_CORPS_GIVES_ITS_REGARDS',
  '20_18_ICE_IN_YOUR_VEINS',
  '20_19_STILL_SHARP',
  '20_20_MOONS_OUT_GOONS_OUT',
  '20_21_BAKE_THAT_CAKE',
  '20_22_INSOMNIA',
  '20_23_PARTING_THE_SEA',
  '20_24_NINE_LIVES_ARE_A_MYTH',
  '20_25_HOW_DID_I_LOSE_ALL_MY_FINGERS?',
  '20_26_PRECIOUS_CARGO',
  '20_27_WELL_BE_BACK',
];
