import { ChangeEvent, Component } from 'react';

export const createFieldChangeHandler = <
  S extends Record<K, string>,
  K extends keyof S
>(
  self: Component<{}, S>,
  key: K
) => ({
  currentTarget: { value },
}: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
  const state = {
    [key]: value,
  } as Pick<S, K>;

  self.setState(state);
};
