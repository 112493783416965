import React, { memo } from 'react';

interface Props {
  className?: string;
  title?: string;
}

const IconCross = (props: Props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    {props.title && <title>{props.title}</title>}
    <path d="M22 2v20H2V2h20zm2-2H0v24h24V0zm-6 16.538l-4.592-4.548 4.546-4.587L16.538 6l-4.545 4.589-4.588-4.543L6 7.451l4.593 4.552-4.547 4.592L7.451 18l4.555-4.596 4.591 4.55L18 16.538z" />
  </svg>
);

export default memo(IconCross);
